import { CATEGORY } from './constants.js';
import { PICTURES_BIRTH } from './pictures_birth.js';
import { PICTURES_BABYSHOWER } from './pictures_babyshower.js'
import { PICTURES_CELEBRATIONS } from './pictures_celebrations.js'
import { PICTURES_ROMANTIC } from './pictures_romantic.js'
import { PICTURES_BAPTISM } from './pictures_baptism.js'
import { PICTURES_WEDDING } from './pictures_wedding.js'


const BIRTH_FILTER_MODAL = {
    id: 'modalBirthFilter',
    title: 'Selecciona una categoría',
    filterOptions: [
        {
            label: `Ver ${CATEGORY.birth.subcategories.all.title}`,
            value: CATEGORY.birth.subcategories.all.key
        },
        {
            label: CATEGORY.birth.subcategories.one_year.title,
            value: CATEGORY.birth.subcategories.one_year.key
        },
        {
            label: CATEGORY.birth.subcategories.two_fourteen_years.title,
            value: CATEGORY.birth.subcategories.two_fourteen_years.key
        },
        {
            label: CATEGORY.birth.subcategories.fifteen_years.title,
            value: CATEGORY.birth.subcategories.fifteen_years.key
        },
        {
            label: CATEGORY.birth.subcategories.fifty_greater_years.title,
            value: CATEGORY.birth.subcategories.fifty_greater_years.key
        },
        {
            label: CATEGORY.birth.subcategories.others.title,
            value: CATEGORY.birth.subcategories.others.key
        }
    ]
}


const getBirthFiltered = (category) => {
    var arrayFiltered = []
    if (category === CATEGORY.birth.subcategories.all.key) {
        arrayFiltered = PICTURES_BIRTH;
    }
    if (category === CATEGORY.birth.subcategories.one_year.key) {
        arrayFiltered = PICTURES_BIRTH.filter(filterBirthByOneYear);
    }
    if (category == CATEGORY.birth.subcategories.two_fourteen_years.key) {
        arrayFiltered = PICTURES_BIRTH.filter(filterBirthByTwoForteenYears);
    }
    if (category === CATEGORY.birth.subcategories.fifteen_years.key) {
        arrayFiltered = PICTURES_BIRTH.filter(filterBirthByFifeteen);
    }
    if (category === CATEGORY.birth.subcategories.fifty_greater_years.key) {
        arrayFiltered = PICTURES_BIRTH.filter(filterBirthByFiftyGreater);
    }
    if (category === CATEGORY.birth.subcategories.others.key) {
        arrayFiltered = PICTURES_BIRTH.filter(filterBirthByOthers);
    }
    return arrayFiltered;
}

function filterBirthByOneYear(item) {
    if (item.subcategory == CATEGORY.birth.subcategories.one_year) {
      return true;
    }
    return false;
}

function filterBirthByTwoForteenYears(item) {
    if (item.subcategory == CATEGORY.birth.subcategories.two_fourteen_years) {
      return true;
    }
    return false;
}

function filterBirthByFifeteen(item) {
    if (item.subcategory == CATEGORY.birth.subcategories.fifteen_years) {
      return true;
    }
    return false;
}

function filterBirthByFiftyGreater(item) {
    if (item.subcategory == CATEGORY.birth.subcategories.fifty_greater_years) {
      return true;
    }
    return false;
}

function filterBirthByOthers(item) {
    if (item.subcategory == CATEGORY.birth.subcategories.others) {
      return true;
    }
    return false;
}




const getSearchResult = (searchValue) => {
    const searchResultFromCategoryList = getSearchMatchesFromCategories(searchValue)
    if (searchResultFromCategoryList.length > 0) {
        return searchResultFromCategoryList
    }

    const searchResultFromBirthSubcategoryList = getSearchMatchesFromBirthSubcategories(searchValue)
    if (searchResultFromBirthSubcategoryList.length > 0) {
        return searchResultFromBirthSubcategoryList
    }

    return getSearchMatchesFromThematics(searchValue)
}

const getSearchMatchesFromCategories = (searchValue) => {
    if (CATEGORY.birth.searchLabel.includes(searchValue.toLowerCase())) {
        return PICTURES_BIRTH
    }
    else if (CATEGORY.babyshower.searchLabel.includes(searchValue.toLowerCase())) {
        return PICTURES_BABYSHOWER
    }
    else if (CATEGORY.celebrations.searchLabel.includes(searchValue.toLowerCase())) {
        return PICTURES_CELEBRATIONS
    }
    else if (CATEGORY.romantic.searchLabel.includes(searchValue.toLowerCase())) {
        return PICTURES_ROMANTIC
    }
    else if (CATEGORY.baptism.searchLabel.includes(searchValue.toLowerCase())) {
        return PICTURES_BAPTISM
    }
    else if (CATEGORY.wedding.searchLabel.includes(searchValue.toLowerCase())) {
        return PICTURES_WEDDING
    }
    return new Array()
}

const getSearchMatchesFromBirthSubcategories = (searchValue) => {
    var arrayFiltered = new Array()
    if (CATEGORY.birth.subcategories.one_year.searchLabel.includes(searchValue.toLowerCase())) {
        arrayFiltered = arrayFiltered.concat(PICTURES_BIRTH.filter(filterBirthByOneYear))
    }
    if (CATEGORY.birth.subcategories.two_fourteen_years.searchLabel.includes(searchValue.toLowerCase())) {
        arrayFiltered = arrayFiltered.concat(PICTURES_BIRTH.filter(filterBirthByTwoForteenYears))
    }
    if (CATEGORY.birth.subcategories.fifteen_years.searchLabel.includes(searchValue.toLowerCase())) {
        arrayFiltered = arrayFiltered.concat(PICTURES_BIRTH.filter(filterBirthByFifeteen))
    }
    if (CATEGORY.birth.subcategories.fifty_greater_years.searchLabel.includes(searchValue.toLowerCase())) {
        arrayFiltered = arrayFiltered.concat(PICTURES_BIRTH.filter(filterBirthByFiftyGreater))
    }
    return arrayFiltered;
}

const getSearchMatchesFromThematics = (searchValue) => {
    var arrayFiltered = new Array()
    PICTURES_BIRTH.forEach((item) => {
        if (item.thematic.searchLabel.includes(searchValue.toLowerCase())) {
            arrayFiltered.push(item)
        }
    })
    PICTURES_BABYSHOWER.forEach((item) => {
        if (item.thematic.searchLabel.includes(searchValue.toLowerCase())) {
            arrayFiltered.push(item)
        }
    })
    PICTURES_CELEBRATIONS.forEach((item) => {
        if (item.thematic.searchLabel.includes(searchValue.toLowerCase())) {
            arrayFiltered.push(item)
        }
    })
    PICTURES_ROMANTIC.forEach((item) => {
        if (item.thematic.searchLabel.includes(searchValue.toLowerCase())) {
            arrayFiltered.push(item)
        }
    })
    PICTURES_BAPTISM.forEach((item) => {
        if (item.thematic.searchLabel.includes(searchValue.toLowerCase())) {
            arrayFiltered.push(item)
        }
    })
    PICTURES_WEDDING.forEach((item) => {
        if (item.thematic.searchLabel.includes(searchValue.toLowerCase())) {
            arrayFiltered.push(item)
        }
    })
    return arrayFiltered;
}


export {
    BIRTH_FILTER_MODAL,
    getBirthFiltered,
    getSearchResult
}