import PathImg001_T01 from '../images_invitations/img_001_T01.jpg';
import PathImg001_T02 from '../images_invitations/img_001_T02.jpg';
import PathImg001_T03 from '../images_invitations/img_001_T03.jpg';
import PathImg002 from '../images_invitations/img_002.jpg';
import PathImg003_T01 from '../images_invitations/img_003_T01.jpg';
import PathImg003_T02 from '../images_invitations/img_003_T02.jpg';
import PathImg004_T01 from '../images_invitations/img_004_T01.jpg';
import PathImg004_T02 from '../images_invitations/img_004_T02.jpg';
import PathImg004_T03 from '../images_invitations/img_004_T03.jpg';
import PathImg004_T04 from '../images_invitations/img_004_T04.jpg';
import PathImg005 from '../images_invitations/img_005.jpg';
import PathImg006 from '../images_invitations/img_006.jpg';
import PathImg007 from '../images_invitations/img_007.jpg';
import PathImg008 from '../images_invitations/img_008.jpg';
import PathImg009 from '../images_invitations/img_009.png';
import PathImg010 from '../images_invitations/img_010.jpg';
import PathImg011 from '../images_invitations/img_011.jpg';


const INVITATIONS = [
    {
        src: PathImg008,
        width: 720,
        height: 1000
    },
    {
        src: PathImg009,
        width: 720,
        height: 1000
    },
    {
        src: PathImg010,
        width: 720,
        height: 1000
    },
    {
        src: PathImg011,
        width: 720,
        height: 1000
    },
    {
        src: PathImg007,
        width: 720,
        height: 1000
    },
    {
        src: PathImg005,
        width: 720,
        height: 1000
    },
    {
        src: PathImg006,
        width: 720,
        height: 1000
    },
    {
        src: PathImg002,
        width: 720,
        height: 1000
    },
    {
        src: PathImg003_T01,
        width: 720,
        height: 1000
    },
    {
        src: PathImg003_T02,
        width: 720,
        height: 1000
    },
    {
        src: PathImg001_T01,
        width: 720,
        height: 1000
    },
    {
        src: PathImg001_T02,
        width: 720,
        height: 1000
    },
    {
        src: PathImg001_T03,
        width: 720,
        height: 1000
    },
    {
        src: PathImg004_T01,
        width: 720,
        height: 1000
    },
    {
        src: PathImg004_T02,
        width: 720,
        height: 1000
    },
    {
        src: PathImg004_T03,
        width: 720,
        height: 1000
    },
    {
        src: PathImg004_T04,
        width: 720,
        height: 1000
    }
]

export { INVITATIONS }