import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FONT_FAMILY, COLOR, SIZE } from '../../utils/constants';


const LabelStyled = styled.p`
    font-family: ${props => props.font_family ? props.font_family : FONT_FAMILY.antipastoPro};
    //line-height: 2.2em; //Commented bcs affect the navbar item height
    font-size: ${props => props.text_size ? props.text_size : SIZE.body};
    color: ${props => props.text_color ? props.text_color : COLOR.black};
    text-align: ${props => props.text_align ? props.text_align : 'left'};
    position: ${props => props.position ? props.position : 'none'};
    margin: ${props => props.margin ? props.margin : ''};
`;

const Label = (props) => {
    return (
        <LabelStyled
            id={props.id}
            className={props.className}
            text_size={props.text_size}
            text_align={props.text_align}
            text_color={props.text_color}
            font_family={props.font_family}
            position={props.position}
            margin={props.margin}>
            {props.children}
        </LabelStyled>
    );
}

Label.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string, //Must have className to apply styled component css properties.
    text_size: PropTypes.string, 
    text_color: PropTypes.string,
    text_align: PropTypes.string,
    font_family: PropTypes.string,
    position: PropTypes.string,
    margin: PropTypes.string
}


export {
    Label
}