
const COLOR = {
    primary: '#EBFCF1',
    primaryDark: '#9BE8B6',
    primaryClear: '#EBFCF1',
    gold: '#C99B02',
    secondary: '#A251A8',
    secondaryDark: '#C43B3B',
    secondaryClear: '#FF9D9C',
    background: '#FCFAF2',
    white: '#FFFFFF',
    ferra: '#6F4C5B',
    black: '#333333',
    gray: '#999999',
    grayDark: '#777777',
    grayClear: '#EDEDED',
    red: '#FF6961'
}

const SIZE = {
    display_top: '54px',
    display: '36px',
    headline_top: '32px',
    headline: '28px',
    title: '22px',
    subtitle: '18px',
    body: '16px',
    caption: '13px',
    small: '11px',
    row_margin: '80px 0',
    inp_radious: '4px' // Input border radious
}

const SCREEN_MEDIA = {
    small: `(min-width: 0px) and (max-width: 600px)`,
    medium : `(min-width: 600px) and (max-width: 992px)`,
    long: `(min-width: 992px) and (max-width: 1200px)`,
    xlong: `(min-width: 1200px)`
}

const FLOAT = {
    left: 'left',
    right: 'right'
}

const LABEL = {
    init: 'Inicio',
    about: 'Acerca de',
    us: 'Nosotros',
    sketch: 'Boceto',
    sketch_shimmer: 'Shimmer',
    sketch_shimmer_silver: 'Plateado',
    sketch_shimmer_golden: 'Dorado',
    sketch_shimmer_colors: 'Colores',
    sketch_background: 'Fondo',
    sketch_background_arch_pink: 'Arco llano rosa',
    sketch_background_arch_sky: 'Arco llano celeste',
    sketch_background_arch_green: 'Arco llano verde',
    sketch_background_square_pink: 'Cuadrado llano rosa',
    sketch_background_square_sky: 'Cuadrado llano celeste',
    sketch_background_square_green: 'Cuadrado llano verde',
    sketch_background_circle_image: 'Circular + imagen',
    sketch_background_circle_curtain: 'Circular + cortina',
    sketch_balloon: 'Globos',
    sketch_balloon_golden: 'Globo dorado',
    sketch_balloon_red: 'Globo rojo',
    sketch_balloon_white: 'Globo blanco',
    sketch_balloon_transparent: 'Globo transparente',
    sketch_balloons_group4_red: 'Grupo de rojos',
    sketch_balloons_group4_golden: 'Grupo de dorados',
    sketch_balloons_group4_white: 'Grupo de blancos',
    sketch_arch: 'Arco',
    sketch_arch_std: 'Estándar',
    sketch_arch_cornered: 'Con esquinas',
    sketch_arch_infinite: 'Infinito',
    sketch_pedestal: 'Pedestal',
    sketch_pedestal_cylinder: 'Cilindro llano',
    sketch_pedestal_square: 'Cubo llano',
    sketch_pedestal_square_trans: 'Cubo acrílico',
    sketch_pedestal_pilar: 'Pilar',
    sketch_table: 'Mesa',
    sketch_table_iron_square: 'Metal cuadrado',
    sketch_table_iron_hourglass: 'Metal hourglass',
    sketch_chairs: 'Silla',
    sketch_chair_king: 'Silla rey',
    sketch_chair_king_small: 'Silla rey small',
    sketch_podiums: 'Podio',
    sketch_podium_circle: 'Podio circular',
    sketch_number: 'Número',
    sketch_number_zero: 'Cero',
    sketch_number_one: 'Uno',
    sketch_number_three: 'Tres',
    sketch_number_five: 'Cinco',
    contact: 'Contacto',
    slogan: 'Organizamos tus momentos inolvidables',
    phone_number_01: '962 075 242',
    phone_number_02: '943 099 535',
    send: 'Enviar',
    close: 'Cerrar',
    clear_all: 'Limpiar todo',
    categories: 'Categorías',
    thematics: 'Temáticas',
    invitations: 'Invitaciones',
    events: 'Eventos',
    thematic_kid_party: 'Fiestas infantiles'
}

const KEY = {
    emailJsService: 'service_dx1fner',
    emailJsTemplate: 'template_2nt1i9r',
    emailJsUserId: 'xXnVu25KX86tmQes5'
}

const URL = {
    home: "/",
    thematics: "/thematics",
    invitations: "/invitations",
    sketch: "/sketch",
    contact: "/contact",
    terms: "/terms",
    whatsapp: "https://api.whatsapp.com/send?phone=51962075242&app=facebook&entry_point=page_cta&fbclid=IwAR2EfDyK9ER4Zxqzcj9e6Ko-E1sSjmql6q7o-2Alp-dtYmkZQXUb_ZFetcg",
    facebook: "https://www.facebook.com/JoeParty-185276802290308",
    tiktok: "https://www.tiktok.com/@joeeventos",
    instagram: "https://www.instagram.com/joeeventosdecoraciones/",
    email: "/contact"
}

const CATEGORY = {
    birth: {
        key: "birth",
        title: "Cumpleaños",
        searchLabel: "cumpleaños",
        subcategories: {
            all: { key: "birth_all", title: "Todos", searchLabel: "----" },
            one_year: { key: "birth_one_year", title: "1 año", searchLabel: "1 año, un año, primer año, fiesta infantil, fiestas infantiles" },
            two_fourteen_years: { key: "birth_two_fourteen_years", title: "2 a 14 años", searchLabel: "2 a 14 años, dos a catorce años, fiesta infantil, fiestas infantiles" },
            fifteen_years: { key: "birth_fifteen_years", title: "15 años", searchLabel: "mis 15 años, mis quince años, mi quinceañera, mi quinceañero" },
            fifty_greater_years: { key: "birth_fifty_greater_years", title: "50 años y mayores", searchLabel: "50 años y mayores, cincuenta años y mayores" },
            others: { key: "birth_others", title: "Otros", searchLabel: "----" }
        }
    },
    babyshower: {
        key: "babyshower",
        title: "Baby Shower",
        searchLabel: "babyshower, baby shower"
    },
    celebrations: {
        key: "celebrations",
        title: "Celebraciones",
        searchLabel: "celebraciones"
    },
    romantic: {
        key: "romantic",
        title: "Ambiente Romántico",
        searchLabel: "ambiente romántico, aniversarios, pedida de mano, cena romántica",
    },
    baptism: {
        key: "baptism",
        title: "Bautizo",
        searchLabel: "bautizo"
    },
    wedding: {
        key: "wedding",
        title: "Matrimonio",
        searchLabel: "matrimonios, bodas"
    }
}

const THEMATIC = {
    mickey: { title: "Disney - Mickey Mouse", searchLabel: "disney mickey mouse" },
    minnie: { title: "Disney - Minnie", searchLabel: "disney minnie, minie mouse" },
    frozenPrincess: { title: "Disney - La princesa Frozen", searchLabel: "disney la princesa frozen, la frozen" },
    sofiaPrincess: { title: "Disney - La princesa Sofía", searchLabel: "disney la princesa sofía, disney la princesa sofia" },
    jazminPrincess: { title: "Disney - La princesa Jazmín", searchLabel: "disney la princesa jazmín, disney la princesa jazmin, disney la princesa jasmin, disney la princesa yasmin" },
    rapunzelPrincess: { title: "Disney - La princesa Rapunzel", searchLabel: "disney la princesa rapunzel" },
    tianaPrincess: { title: "Disney - La princesa Tiana", searchLabel: "disney la princesa tiana" },
    theGiftOfFamily: { title: "Disney - Encanto", searchLabel: "disney encanto, el regalo de la familia" },
    winnieThePooh: { title: "Disney - Winnie The Pooh", searchLabel: "disney winnie the pooh" },
    disneyPrincess: { title: "Disney - Princesas", searchLabel: "disney princesas, princesas disney, disney princess" },
    mermaid: { title: "Disney - La Sirenita", searchLabel: "disney la sirenita" },
    looneyTunes: { title: "Looney Tunes", searchLabel: "los looney tunes, los lonney tunes, los luney tunes" },
    spiderman: { title: "Spiderman", searchLabel: "spiderman, el hombre araña" },
    barbie: { title: "Barbie", searchLabel: "la barbie" },
    skibidiToilet: { title: "Skibidi Toilet", searchLabel: "skibidi toilet" },
    mashaAndBear: { title: "Masha y el oso", searchLabel: "la masha y el oso" },
    farm: { title: "La granja", searchLabel: "la granja" },
    zoo: { title: "El Zoológico", searchLabel: "el zoológico, el zoologico" },
    heny: { title: "La Gallina pintadita", searchLabel: "la gallina pintadita" },
    kuromi: { title: "Kuromi", searchLabel: "kuromi" },
    safari: { title: "Safari", searchLabel: "safari" },
    pawPatroll: { title: "Paw Patroll", searchLabel: "paw patroll" },
    mario: { title: "Mario Bros", searchLabel: "super mario bros, súper mario bros" },
    pokemon: { title: "Pokémon", searchLabel: "pokemon, pokémon" },
    plimplim: { title: "Plim Plim", searchLabel: "plim plim, plimplim" },
    neon: { title: "Neon", searchLabel: "neon, neón" },
    schoolAnniversary: { title: "Aniversario escolar", searchLabel: "aniversario escolar, centro educativo" },
    schoolPromo: { title: "Promoción escolar", searchLabel: "promoción escolar, promocion escolar, centro educativo" },
    motherDay: { title: "Día de la madre", searchLabel: "día de la madre, dia de la madre, día de la mamá" },
    childDay: { title: "Día del niño", searchLabel: "día del niño, dia del niño" },
    graduation: { title: "Graduación", searchLabel: "graduación graduacion" },
    musicCriollaDay: { title: "Día de la canción criolla", searchLabel: "día de la canción criolla, dia de la cancion criolla, día de la música criolla, dia de la musica criolla" },
    romanticMarriageProposal: { title: "Pedida de mano", searchLabel: "pedida de mano, proposición de casamiento" },
    romanticAnniversary: { title: "Aniversario de amor", searchLabel: "aniversario de enamorados, aniversario de casados" },
    custom: { title: "Personalizado", searchLabel: "personalizado" },
    none: { title: "----", searchLabel: "----" }
}

const FONT_FAMILY = {
    noteworthyBold: "Noteworthy Bold",
    noteworthyLight: "Noteworthy Light",
    unicorns: "unicorns_are_awesomeregular",
    antipastoProBold: "Antipasto Pro Bold",
    antipastoProLight: "Antipasto Pro Light",
    antipastoProExtraBold: "Antipasto Pro Extra Bold",
    antipastoProHairline: "Antipasto Pro Hairline",
    antipastoPro: "Antipasto Pro",
    antipastoProDemiBold: "Antipasto Pro Demi Bold",
    antipastoProThin: "Antipasto Pro Thin",
    antipastoProMedium: "Antipasto Pro Medium",
    antipastoProExtraLight: "Antipasto Pro Extra Light"
}

export {
    COLOR,
    SIZE,
    SCREEN_MEDIA,
    LABEL,
    FLOAT,
    KEY,
    URL,
    CATEGORY,
    THEMATIC,
    FONT_FAMILY
}