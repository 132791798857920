import { CATEGORY, THEMATIC } from './constants.js';

import PathImgWedding_001 from '../images_categories/wedding/img_001.jpg';
import PathImgWedding_002_001 from '../images_categories/wedding/img_002_01.jpg';
import PathImgWedding_002_002 from '../images_categories/wedding/img_002_02.jpg';
import PathImgWedding_002_003 from '../images_categories/wedding/img_002_03.jpg';

const PICTURES_WEDDING = [
    {
        src: PathImgWedding_002_001,
        category: CATEGORY.wedding,
        thematic: THEMATIC.none,
        title: CATEGORY.wedding.title
    },
    {
        src: PathImgWedding_002_002,
        category: CATEGORY.wedding,
        thematic: THEMATIC.none,
        title: CATEGORY.wedding.title
    },
    {
        src: PathImgWedding_002_003,
        category: CATEGORY.wedding,
        thematic: THEMATIC.none,
        title: CATEGORY.wedding.title
    },
    {
        src: PathImgWedding_001,
        category: CATEGORY.wedding,
        thematic: THEMATIC.none,
        title: CATEGORY.wedding.title
    }
]

export { PICTURES_WEDDING }