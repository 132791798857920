import React from 'react';
import PropTypes from 'prop-types';
import 'materialize-css';
import styled from 'styled-components';
import { Navbar, NavItem } from 'react-materialize';
import { Label } from '../atoms/label.jsx';
import { COLOR, SIZE, SCREEN_MEDIA, LABEL, FONT_FAMILY } from '../../utils/constants.js';
import SVG from 'react-inlinesvg';

import PathLogo from '../../images/img_logo_text_ferra.svg';
import PathBurger from '../../icons/ic_burger.svg';


const Logo = styled(SVG)`
    height: 35px;
    top: 10px;
    position: relative;
`;
const Burger = styled(SVG)`
    fill: ${COLOR.ferra};
    width: 20px;
    position: relative;
`;

const logo_dom = <a href='/'><Logo src={PathLogo}></Logo></a>
const burger_dom = <Burger src={PathBurger}></Burger>;


const NavbarStyled = styled(Navbar)`
    background-color: ${COLOR.primary};
    @media only screen and ${SCREEN_MEDIA.small} {
        padding: 0 0px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        padding: 0 10px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        padding: 0 100px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        padding: 0 100px;
    }
`;

const NavItemStyled = styled(NavItem)`
    background-color: ${props => props.selected ? COLOR.primaryDark : COLOR.primary};
`;

const NavItemLabelStyled = styled(Label)`
    color: ${COLOR.ferra};
`;

const Header = (props) => {

    var init_selected = false;
    var thematics_selected = false;
    var sketch_selected = false;
    var invitations_selected = false;
    var contact_selected = false;

    switch (props.selected) {
        case LABEL.init:
            init_selected = true;
            break;
        case LABEL.thematics:
            thematics_selected = true;
            break;
        case LABEL.sketch:
            sketch_selected = true;
            break;
        case LABEL.invitations:
            invitations_selected = true;
            break;
        case LABEL.contact:
            contact_selected = true;
            break;
    }
    

    return (
        <NavbarStyled 
            alignLinks='right'
            brand={logo_dom}
            fixed={true}
            menuIcon={burger_dom}>

            <NavItemStyled
                href='/'
                selected={init_selected}>
                <NavItemLabelStyled
                    font_family={FONT_FAMILY.noteworthyBold}>
                    {LABEL.init}
                </NavItemLabelStyled>
            </NavItemStyled>

            <NavItemStyled
                href='/thematics'
                selected={thematics_selected}>
                <NavItemLabelStyled
                    font_family={FONT_FAMILY.noteworthyBold}>
                    {LABEL.thematics}
                </NavItemLabelStyled>
            </NavItemStyled>

            <NavItemStyled
                href='/sketch'
                selected={sketch_selected}>
                <NavItemLabelStyled
                    font_family={FONT_FAMILY.noteworthyBold}>
                    {LABEL.sketch}
                </NavItemLabelStyled>
            </NavItemStyled>

            <NavItemStyled
                href='/invitations'
                selected={invitations_selected}>
                <NavItemLabelStyled
                    font_family={FONT_FAMILY.noteworthyBold}>
                    {LABEL.invitations}
                </NavItemLabelStyled>
            </NavItemStyled>

            <NavItemStyled
                href='/contact'
                selected={contact_selected}>
                <NavItemLabelStyled
                    font_family={FONT_FAMILY.noteworthyBold}>
                    {LABEL.contact}
                </NavItemLabelStyled>
            </NavItemStyled>

        </NavbarStyled>
    );
    
}

Header.propTypes = {
    selected: PropTypes.string
}

export default Header;