import React, { useState, useEffect } from 'react';
import $ from 'jquery'; 
import M from 'materialize-css';
import styled from 'styled-components';
import { Container, Col, Modal, Parallax } from 'react-materialize';
import { CATEGORY, LABEL, COLOR, SIZE, SCREEN_MEDIA, FONT_FAMILY, URL, THEMATIC } from '../../utils/constants.js';
import Slider from '../organism/slider.jsx';
import Row from '../organism/row.jsx';
import RowInfo from '../organism/rowinfo.jsx';
import TitleSect from '../atoms/titlesect.jsx';
import CardContent from '../molecules/cardcontent.jsx';
import Divider from '../atoms/divider.jsx';
import Contact from '../organism/contact.jsx';
import FixedButtons from '../molecules/fixedbuttons.jsx';
import { XMAS_WINNERS_MODAL } from '../../utils/managerabout.js';
import Button from '../atoms/button.jsx';
import { Label } from '../atoms/label.jsx';

import PathWapp from '../../icons/ic_wapp.svg';
import PathFb from '../../icons/ic_fb_colored.svg';
import PathTikTok from '../../icons/ic_tiktok.svg';
import PathInsta from '../../icons/ic_instagram_colored.svg';

import imgSl01 from '../../images_categories/birth01/img_009_001.jpg';
import imgSl02 from '../../images_categories/birth15/img_009_009.jpg';
import imgSl03 from '../../images_categories/romantic/img_002_001.jpg';
import imgSl04 from '../../images_categories/birth50G/img_001_001.jpg';
import imgSl05 from '../../images_categories/babyshower/img_004_01.jpg';
import imgSl06 from '../../images_categories/celebrations/img_011_001.jpg';

import PathImgTeam from '../../images/img_team.png';
import PathImg01 from '../../images/img_logo_bg_green.svg';
import PathThematic from '../../images_categories/romantic/img_002_001_s.jpg';
import PathEvents from '../../images_categories/celebrations/img_015_001_s.jpg';

import PathImgWedding from '../../images/img_wedding.svg';
import PathImgStorkBaby from '../../images/img_stork_baby.svg';
import PathImgBaptism from '../../images/img_baptism.svg';
import PathImgBirth from '../../images/img_birth.svg';
import PathImgRomantic from '../../images/img_romantic.svg';
import PathImgHalloween from '../../images/img_halloween.svg';

import PathBgColours from '../../images/bg_colours.webp';
import TextIcon from '../atoms/texticon';

import PathImgWinner00 from '../../images/img_winner_00.png';
import PathImgWinner01 from '../../images/img_winner_01.png';
import PathImgWinner02 from '../../images/img_winner_02.png';

import PathImgModalBackground from '../../images/img_xmas.png'


const ParallaxStyled = styled(Parallax)`
    height: 700px;
    margin: 60px 0;
    // Cover and center background image: //
    background-position: 50% 50%; /*El cuadro ajustado se puede centrar horizontal y verticalmente*/
    background-size: cover; /*El cuadro de la imagen se ajusta a la dimensión más pequeña del div con el objetivo de cubrirla*/
    background-repeat: no-repeat;
    // To make the overlay: //
    position: relative;
    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: ${COLOR.background};
        opacity: 0.8;
    }
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 1560px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        height: 950px;
    }
`;

const ParallaxBody = styled(Container)`
    position: relative;
    z-index: 1;
`;

const BodyAbout = () => {

    const [xmasWinnersModal, setXmasWinnersModal] = useState();

    useEffect(() => {
        $(window).on('load', function() {
            // ready method is deprecated
            var xmasModal = M.Modal.getInstance($(`#${XMAS_WINNERS_MODAL.id}`)); // Init modal
            setXmasWinnersModal(xmasModal);

            //xmasModal.open();
        });

        var modalDiv = $(`#${XMAS_WINNERS_MODAL.id}`).find('.modal-content')
        modalDiv.css("background-image", `url(${PathImgModalBackground})`);
        modalDiv.css("background-position", "50% 50%");
        modalDiv.css("background-size", "cover");
        modalDiv.css("background-repeat", "no-repeat");
        modalDiv.css("position", "relative");

        var modalH4 = $(`#${XMAS_WINNERS_MODAL.id} h4`)
        modalH4.css("font-family", FONT_FAMILY.antipastoProLight);

    }, [])

    return (
        <div>

            <Slider 
                showBubble={true}
                margin='0 0'
                slides_info={[
                    { bg_path: imgSl01, title: CATEGORY.birth.subcategories.one_year.title, layer_opacity: 0.25 },
                    { bg_path: imgSl02, title: CATEGORY.birth.subcategories.fifteen_years.title, layer_opacity: 0.25 },
                    { bg_path: imgSl03, title: CATEGORY.romantic.title, layer_opacity: 0.25 },
                    { bg_path: imgSl04, title: CATEGORY.birth.subcategories.fifty_greater_years.title, layer_opacity: 0.25 },
                    { bg_path: imgSl05, title: CATEGORY.babyshower.title, layer_opacity: 0.25 },
                    { bg_path: imgSl06, title: CATEGORY.celebrations.title, layer_opacity: 0.25 }
                ]}/>

            <Container>
                <FixedButtons
                items={[
                    { href: URL.whatsapp, ic_path: PathWapp, ic_size: '50px' },
                    { href: URL.facebook, ic_path: PathFb, ic_size: '50px' },
                    { href: URL.tiktok, ic_path: PathTikTok, ic_size: '50px' },
                    { href: URL.instagram, ic_path: PathInsta, ic_size: '50px' }
                    ]}
                aligment='right'/>

                <Modal
                    id={ XMAS_WINNERS_MODAL.id }
                    header={ XMAS_WINNERS_MODAL.title }
                    fixedFooter={ false } //If true breaks DatePickers
                    actions={[
                    <Button 
                        onClick={() => { xmasWinnersModal.close() }}
                        width='120px'
                        height='46px'
                        bg_color={COLOR.primary}
                        float='right'>
                        <Label
                            text_color={ COLOR.ferra }
                            text_size={SIZE.headline}
                            font_family={FONT_FAMILY.unicorns}
                            margin='auto'>
                            Cerrar
                        </Label>
                    </Button>
                    ]}>
                        <Row margin='20px 0 0 0'>
                            <TextIcon
                                ic_path={ PathImgWinner00 }
                                ic_size='50px'
                                ic_color={COLOR.ferra}
                                ic_top='15px'
                                font_family={FONT_FAMILY.noteworthyBold}
                                text_size='20px'
                                margin='15px 0 0 0'>
                                { XMAS_WINNERS_MODAL.winners[0].name }
                                </TextIcon>
                        </Row>
                        <Row margin='20px 0 0 0'>
                            <TextIcon
                                ic_path={ PathImgWinner01 }
                                ic_size='50px'
                                ic_color={COLOR.ferra}
                                ic_top='15px'
                                font_family={FONT_FAMILY.noteworthyBold}
                                text_size='20px'
                                margin='15px 0 0 0'>
                                { XMAS_WINNERS_MODAL.winners[1].name }
                                </TextIcon>
                        </Row>
                        <Row margin='20px 0 20px 0'>
                            <TextIcon
                                ic_path={ PathImgWinner02 }
                                ic_size='50px'
                                ic_color={COLOR.ferra}
                                ic_top='15px'
                                font_family={FONT_FAMILY.noteworthyBold}
                                text_size='20px'
                                margin='15px 0 0 0'>
                                { XMAS_WINNERS_MODAL.winners[2].name }
                                </TextIcon>
                        </Row>
                </Modal>

                <RowInfo
                    margin='20px 0 0 0'
                    img_path={PathEvents}
                    img_right={false}
                    info_background_color={COLOR.background}
                    title={LABEL.events}
                    descs={[
                        'Síguenos en nuestras redes sociales y entérate de los eventos anuales que celebramos para regalarles una sonrisa a los más pequeños del hogar.',
                        'Nuestros regalitos de Navidad y Halloween los hacemos pensando en ellos. Pues, su felicidad nos entusiasma a seguir adelante.'
                        ]}/>

                <RowInfo
                    margin='20px 0 0 0'
                    img_path={PathThematic}
                    img_right={false}
                    info_background_color={COLOR.background}
                    title={LABEL.thematics}
                    descs={['Nuestro catálogo se ha expandido exponencialmente y cada vez son más las temáticas que tenemos para ustedes.']}
                    bullet_items={[
                        { item: THEMATIC.mickey.title },
                        { item: THEMATIC.disneyPrincess.title },
                        { item: THEMATIC.looneyTunes.title },
                        { item: THEMATIC.barbie.title },
                        { item: THEMATIC.mario.title },
                        { item: 'Y muchos más!' }
                    ]}/>

            </Container>

            <ParallaxStyled
                image={<img alt="" src={PathBgColours}/>}
                options={{responsiveThreshold: 0}}
                children={
                    <ParallaxBody>
                        <Row margin='40px 0 0 0'>
                            <TitleSect>{LABEL.categories}</TitleSect>
                        </Row>
                        <Row>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathImgBirth} 
                                    ic_height='120px'
                                    title={ CATEGORY.birth.title }
                                    title_size={SIZE.title}
                                    title_color={COLOR.ferra}
                                    alignment='center'
                                    margin='0px 20px'
                                    href={URL.thematics}/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathImgStorkBaby} 
                                    ic_height='120px'
                                    title={ CATEGORY.babyshower.title }
                                    title_size={SIZE.title}
                                    title_color={COLOR.ferra}
                                    alignment='center'
                                    margin='0px 20px'
                                    href={URL.thematics}/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathImgHalloween} 
                                    ic_height='120px'
                                    title={ CATEGORY.celebrations.title } 
                                    title_size={SIZE.title}
                                    title_color={COLOR.ferra}
                                    alignment='center'
                                    margin='0px 20px'
                                    href={URL.thematics}/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathImgRomantic} 
                                    ic_height='120px'
                                    title={ CATEGORY.romantic.title } 
                                    title_size={SIZE.title}
                                    title_color={COLOR.ferra}
                                    alignment='center'
                                    margin='0px 20px'
                                    href={URL.thematics}/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathImgWedding} 
                                    ic_height='120px'
                                    title={ CATEGORY.wedding.title }
                                    title_size={SIZE.title}
                                    title_color={COLOR.ferra}
                                    alignment='center'
                                    margin='0px 20px'
                                    href={URL.thematics}/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathImgBaptism} 
                                    ic_height='120px'
                                    title={ CATEGORY.baptism.title }
                                    title_size={SIZE.title}
                                    title_color={COLOR.ferra}
                                    alignment='center'
                                    margin='0px 20px'
                                    href={URL.thematics}/>
                            </Col>
                        </Row>
                    </ParallaxBody>
                }/>

            <Container>

                <RowInfo
                    margin='20px 0 0 0'
                    img_path={PathImgTeam}
                    img_right={false}
                    info_background_color={COLOR.background}
                    title='Nosotros'
                    descs={[
                        'Somos un equipo profesional enfocado en la decoración de eventos conmemorables. Empezamos con las decoraciones de fiestas infantiles, pero gracias a la buena acogida por parte de los clientes, nos hemos expandido hasta las temáticas de Baby Shower, Revelación de género, Celebraciones escolares, Ambiente romántico, etc.',
                        'En JoeEventos contamos con un equipo experto y sobre todo apasionado que disfruta del trabajo de la decoración. Gracias a ello, conseguimos los mejores resultados y nuestros clientes lo avalan. ',
                    ]}/>

                <RowInfo
                    margin='20px 0 0 0'
                    img_path={PathImg01}
                    img_right={false}
                    info_background_color={COLOR.background}
                    title='Filosofía'
                    descs={[
                        'Nuestro enfoque principal es conseguir que el cliente disfrute de un momento inolvidable. Por esta razón, contamos con una gran variedad de materiales para conseguir las temáticas más populares y hasta las más personalizadas.',
                        'Nos adaptamos a las tendencias integrando las últimas novedades con el fin de conseguir que tu evento sea tan bello para compartirlo con tus amigos en las redes sociales.'
                    ]}/>

                <Divider/>

                <Row onClick={ () => xmasWinnersModal.open() }>
                    <TitleSect>{LABEL.contact}</TitleSect>
                </Row>
                <Row className='valign-wrapper'>
                    <Col s={12} m={12} l={12} xl={10} offset='xl1'>
                        <Contact/>
                    </Col>
                </Row>
                
            </Container>
            
        </div>
    );
}

export default BodyAbout;