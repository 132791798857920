import React from 'react';
import PropTypes, { object } from 'prop-types';
import styled from 'styled-components';
import { COLOR, SIZE } from '../../utils/constants.js';
import TextIcon from '../atoms/texticon';

import PathIcBullet3 from '../../icons/ic_bullet3.svg';
import PathIcBullet4 from '../../icons/ic_bullet4.svg';


const Container = styled.div`
    margin: ${props => props.margin ? props.margin : ''};
`;

const BulletListContent = styled.ul`
    overflow: auto;  // To avoid ul content outside of div.
`

const BulletItemContent = styled.li`
    float: left;
    clear: left;
 `;

const Bullets = (props) => {

     const itemsLi = props.items?.map((bullet, index) => {
          if (bullet.subitems && bullet.subitems.length > 0) {
               const subitemsLi = bullet.subitems?.map((subitem, subindex) => 
                    <BulletItemContent
                         key={subindex}>
                         <TextIcon
                              key={subindex}
                              ic_path={props.subitem_bullet_ic_path}
                              ic_size='10px'
                              ic_color={COLOR.black}
                              text_color={props.subitem_text_color}
                              text_size={props.subitem_text_size}
                              font_family={props.font_family}
                              valign={false}
                              margin='6px 0 0 80px'>
                              {subitem}
                         </TextIcon>
                    </BulletItemContent>
               );
               return (
                    <BulletItemContent
                         key={index}>
                         <TextIcon
                              key={index}
                              ic_path={props.item_bullet_ic_path}
                              ic_size='10px'
                              ic_color={COLOR.ferra}
                              ic_top='15px'
                              text_color={props.item_text_color}
                              text_size={props.item_text_size}
                              font_family={props.font_family}
                              valign={false}
                              margin='15px 0 0 0'>
                              {bullet.item}
                         </TextIcon>
                         <BulletListContent> {subitemsLi} </BulletListContent>
                    </BulletItemContent>
               )
          }
          else {
               return (
                    <BulletItemContent
                         key={index}>
                         <TextIcon
                              key={index}
                              ic_path={props.item_bullet_ic_path}
                              ic_size='10px'
                              ic_color={COLOR.ferra}
                              ic_top='15px'
                              text_color={props.item_text_color}
                              text_size={props.item_text_size}
                              font_family={props.font_family}
                              valign={false}
                              margin='12px 0 0 0'>
                              {bullet.item}
                         </TextIcon>
                    </BulletItemContent>
               )
          }
     });

     return (
     <Container
          margin={props.margin}>
          <BulletListContent>{itemsLi}</BulletListContent>
     </Container>)
}

Bullets.defaultProps = {
     item_bullet_ic_path: PathIcBullet3,
     item_text_size: SIZE.title,
     item_text_color: COLOR.black,
     subitem_bullet_ic_path: PathIcBullet4,
     subitem_text_size: SIZE.title,
     subitem_text_color: COLOR.black
}

Bullets.propTypes = {
     margin: PropTypes.string,
     item_text_size: PropTypes.string,
     item_text_color: PropTypes.string,
     item_bullet_ic_path: PropTypes.string,
     ic_bullet_size: PropTypes.string,
     font_family: PropTypes.string,
     items: PropTypes.arrayOf(object)
}

export default Bullets;