import React, { useRef } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import TextInput from '../atoms/textinput.jsx';
import TextArea from '../atoms/textarea.jsx';
import Button from '../atoms/button.jsx';
import { Label } from '../atoms/label.jsx';
import { COLOR, SIZE, KEY, LABEL, FONT_FAMILY } from '../../utils/constants.js';

const Containter = styled.div`
    overflow: auto; // To cover height with contents
    padding: 30px;
    background-color: ${COLOR.background};
    border-radius: 25px;
    -webkit-box-shadow: -1px 0px 22px -3px rgba(153,153,153,1);
    -moz-box-shadow: -1px 0px 22px -3px rgba(153,153,153,1);
    box-shadow: -1px 0px 22px -3px rgba(153,153,153,1);
`;

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(KEY.emailJsService, KEY.emailJsTemplate, form.current, KEY.emailJsUserId)
            .then((result) => {
                console.log("message sent");
                console.log(result.text);
                alert("Mensaje recibido");
            }, (error) => {
                console.log("message error");
                console.log(error.text);
            });
        e.target.reset();
    }

    return(
        <Containter>
            <Label
                margin='20px 0 50px 0' 
                text_size={SIZE.headline_top}
                text_color={COLOR.ferra}
                font_family={FONT_FAMILY.unicorns}>
                Envíanos tu mensaje y en breve nos pondremos en contacto contigo.
            </Label>

            <form ref={form} onSubmit={sendEmail}>
            <TextInput 
                id='name'
                name='name'
                type='text' 
                placeholder='Nombre'
                margin='30px 0'
                onChange={ () => {} }/>
            <TextInput 
                id='email'
                name='email'
                type='email' 
                placeholder='E-mail'
                margin='30px 0'
                onChange={ () => {} }/>
            <TextInput 
                id='phone'
                name='phone'
                type='tel' 
                placeholder='Celular'
                margin='30px 0'
                onChange={ () => {} }/>
            <TextInput 
                id='address'
                name='address'
                type='text' 
                placeholder='Dirección'
                margin='30px 0'
                onChange={ () => {} }/>
            <TextArea
                id='message'
                name='message'
                type='text'
                placeholder='Mensaje'
                margin='30px 0'
                onChange={ () => {} }/>
            <Button
                type='submit'
                width='120px'
                height='46px'
                bg_color={ COLOR.primary }
                margin='10px 0 0 0'
                float='right'
                onChange={ () => {} }>
                <Label
                    text_color={ COLOR.ferra }
                    text_size={SIZE.headline}
                    font_family={FONT_FAMILY.unicorns}
                    margin='auto'>
                    {LABEL.send}
                </Label>
            </Button>
            </form>
            
        </Containter>
    );
}

export default Contact;