import React from 'react';
import PropTypes, { string, object } from 'prop-types';
import styled from 'styled-components';
import TextButton from '../atoms/textbutton.jsx';

const Container = styled.div`
    width: 65px;
    position: fixed;
    top: 70%;  
    -webkit-transform: translateY(-70%);
    -ms-transform: translateY(-70%);
    transform: translateY(-70%);
    z-index: 1;
    ${ props => props.right ? 'right: ' + props.right : '' };
`;

const FixedButtons = (props) => {

    const itemList = props.items?.map((button, index) => {
        return (
            <TextButton
                key={index}
                href={button.href}
                target="_blank"
                ic_path={button.ic_path}
                ic_size={button.ic_size}
                margin='7px 0 0 0'/>
        )
    });

    var aligment
    if (props.aligment == "right") {
        aligment = '10px'
    }

    return (
        <Container
            align={props.align}
            right={aligment}>
            { itemList }
        </Container>
    )
}

FixedButtons.propTypes = {
    items: PropTypes.arrayOf(object),
    alignment: PropTypes.string
}

export default FixedButtons;