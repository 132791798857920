import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/organism/header.jsx';
import Footer from './components/organism/footer.jsx';
import BodyAbout from './components/templates/bodyabout.jsx';
import BodyThematic from './components/templates/bodythematic.jsx';
import BodyInvitations from './components/templates/bodyinvitations.jsx';
import BodyContact from './components/templates/bodycontact.jsx';
import BodySketch from './components/templates/bodysketch.jsx';
import BodyTerms from './components/templates/bodyterms.jsx';
import { Root } from './utils/global.js';
import { LABEL, URL } from './utils/constants.js';
import { 
    FontMonaco,
    FontUnicornsAreAwesome,
    FontNoteworthyLight,
    FontNoteworthyBold,
    FontAntipastoProBold, 
    FontAntipastoProLight, 
    FontAntipastoProExtraBold, 
    FontAntipastoProHairline,
    FontAntipastoPro,
    FontAntipastoProDemiBold,
    FontAntipastoProThin,
    FontAntipastoProMedium,
    FontAntipastoProExtralight
} from './utils/fonts.js';

const App = () => {
     return(
     <div>
          <Root/>
          <FontMonaco/>
          <FontUnicornsAreAwesome/>
          <FontNoteworthyLight/>
          <FontNoteworthyBold/>
          <FontAntipastoProBold/>
          <FontAntipastoProLight/>
          <FontAntipastoProExtraBold/>
          <FontAntipastoProHairline/>
          <FontAntipastoPro/>
          <FontAntipastoProDemiBold/>
          <FontAntipastoProThin/>
          <FontAntipastoProMedium/>
          <FontAntipastoProExtralight/>
          
          <Router>
            <Routes>

                <Route exact path={URL.home} element={
                    <div>
                        <Header selected={LABEL.init}/>
                        <BodyAbout/>
                    </div>
                }/>

                <Route path={URL.thematics} element={
                    <div>
                        <Header selected={LABEL.thematics}/>
                        <BodyThematic/>
                    </div>
                }/>

                <Route path={URL.sketch} element={
                    <div>
                        <Header selected={LABEL.sketch}/>
                        <BodySketch/>
                    </div>
                }/>

                <Route path={URL.invitations} element={
                    <div>
                        <Header selected={LABEL.invitations}/>
                        <BodyInvitations/>
                    </div>
                }/>

                <Route path={URL.contact} element={
                    <div>
                        <Header selected={LABEL.contact}/>
                        <BodyContact/>
                    </div>
                }/>

                <Route path={URL.terms} element={
                    <div>
                        <Header/>
                        <BodyTerms/>
                    </div>
                }/>

            </Routes>
        </Router>

        <Footer/>
     </div>
     );
}

export default App;