import { createGlobalStyle } from 'styled-components';

import Monaco from '../fonts/Monaco.woff';

import UnicornsAreAwesome from '../fonts/unicornsareawesome.woff';
import UnicornsAreAwesome2 from '../fonts/unicornsareawesome.woff2';

import NoteworthyLight from '../fonts/noteworthy-light.woff';
import NoteworthyLight2 from '../fonts/noteworthy-light.woff2';

import NoteworthyBold from '../fonts/noteworthy-bold.woff';
import NoteworthyBold2 from '../fonts/noteworthy-bold.woff2';

import AntipastoProBold from '../fonts/AntipastoPro-Bold.woff';
import AntipastoProBold2 from '../fonts/AntipastoPro-Bold.woff2';

import AntipastoProLight from '../fonts/AntipastoPro-Light.woff';
import AntipastoProLight2 from '../fonts/AntipastoPro-Light.woff2';

import AntipastoProExtraBold from '../fonts/AntipastoPro-ExtraBold.woff';
import AntipastoProExtraBold2 from '../fonts/AntipastoPro-ExtraBold.woff2';

import AntipastoProHairline from '../fonts/AntipastoPro-Hairline.woff';
import AntipastoProHairline2 from '../fonts/AntipastoPro-Hairline.woff2';

import AntipastoPro from '../fonts/AntipastoPro.woff';
import AntipastoPro2 from '../fonts/AntipastoPro.woff2';

import AntipastoProDemiBold from '../fonts/AntipastoPro-DemiBold.woff';
import AntipastoProDemiBold2 from '../fonts/AntipastoPro-DemiBold.woff2';

import AntipastoProThin from '../fonts/AntipastoPro-Thin.woff';
import AntipastoProThin2 from '../fonts/AntipastoPro-Thin.woff2';

import AntipastoProMedium from '../fonts/AntipastoPro-Medium.woff';
import AntipastoProMedium2 from '../fonts/AntipastoPro-Medium.woff2';

import AntipastoProExtraLight from '../fonts/AntipastoPro-Extralight.woff';
import AntipastoProExtraLight2 from '../fonts/AntipastoPro-Extralight.woff2';




const FontUnicornsAreAwesome = createGlobalStyle`
    @font-face {
        font-family: 'unicorns_are_awesomeregular';
        src:
        url(${UnicornsAreAwesome2}) format('woff2'),
        url(${UnicornsAreAwesome}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
`;


const FontMonaco = createGlobalStyle`
    @font-face {
        font-family: 'Monaco';
        src: local('Monaco'), local('Monaco'),
        url(${Monaco}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
`;

const FontNoteworthyLight = createGlobalStyle`
    @font-face {
        font-family: 'Noteworthy Light';
        src:
        url(${NoteworthyLight2}) format('woff2'),
        url(${NoteworthyLight}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
`;

const FontNoteworthyBold = createGlobalStyle`
    @font-face {
        font-family: 'Noteworthy Bold';
        src:
        url(${NoteworthyBold2}) format('woff2'),
        url(${NoteworthyBold}) format('woff');
        font-weight: bold;
        font-style: normal;
    }
`;

const FontAntipastoProBold = createGlobalStyle`
    @font-face {
        font-family: 'Antipasto Pro Bold';
        src: url(${AntipastoProBold2}) format('woff2'),
            url(${AntipastoProBold}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
`;

const FontAntipastoProLight = createGlobalStyle`
    @font-face {
        font-family: 'Antipasto Pro Light';
        src: url(${AntipastoProLight2}) format('woff2'),
            url(${AntipastoProLight}) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
`;

const FontAntipastoProExtraBold = createGlobalStyle`
    @font-face {
        font-family: 'Antipasto Pro Extra Bold';
        src: url(${AntipastoProExtraBold2}) format('woff2'),
            url(${AntipastoProExtraBold}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
`;

const FontAntipastoProHairline = createGlobalStyle`
    @font-face {
        font-family: 'Antipasto Pro Hairline';
        src: url(${AntipastoProHairline2}) format('woff2'),
            url(${AntipastoProHairline}) format('woff');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }
`;

const FontAntipastoPro = createGlobalStyle`
    @font-face {
        font-family: 'Antipasto Pro';
        src: url(${AntipastoPro2}) format('woff2'),
            url(${AntipastoPro}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
`;

const FontAntipastoProDemiBold = createGlobalStyle`
    @font-face {
        font-family: 'Antipasto Pro Demi Bold';
        src: url(${AntipastoProDemiBold2}) format('woff2'),
            url(${AntipastoProDemiBold}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }
`;

const FontAntipastoProThin = createGlobalStyle`
    @font-face {
        font-family: 'Antipasto Pro Thin';
        src: url(${AntipastoProThin2}) format('woff2'),
            url(${AntipastoProThin}) format('woff');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }
`;

const FontAntipastoProMedium = createGlobalStyle`
    @font-face {
        font-family: 'Antipasto Pro Medium';
        src: url(${AntipastoProMedium2}) format('woff2'),
            url(${AntipastoProMedium}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
`;

const FontAntipastoProExtralight = createGlobalStyle`
    @font-face {
        font-family: 'Antipasto Pro Extra Light';
        src: url(${AntipastoProExtraLight2}) format('woff2'),
            url(${AntipastoProExtraLight}) format('woff');
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }
`;


export { 
    FontMonaco,
    FontUnicornsAreAwesome,
    FontNoteworthyLight,
    FontNoteworthyBold,
    FontAntipastoProBold,
    FontAntipastoProLight,
    FontAntipastoProExtraBold,
    FontAntipastoProHairline,
    FontAntipastoPro,
    FontAntipastoProDemiBold,
    FontAntipastoProThin,
    FontAntipastoProMedium,
    FontAntipastoProExtralight
}