import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Parallax } from 'react-materialize';
import { COLOR, SIZE, SCREEN_MEDIA, FONT_FAMILY } from '../../utils/constants.js';
import { Label } from '../atoms/label.jsx';

import PathBgColours from '../../images/img_banner.jpg';


const ParallaxStyled = styled(Parallax)`
    width: 100%;
    height: 300px;
    // Cover and center background image: //
    background-position: 50% 50%; /*El cuadro ajustado se puede centrar horizontal y verticalmente*/
    background-size: cover; /*El cuadro de la imagen se ajusta a la dimensión más pequeña del div con el objetivo de cubrirla*/
    background-repeat: no-repeat;
    // To make the overlay: //
    position: relative;
    z-index: -1;
    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: ${COLOR.primaryDark};
        opacity: 0.2;
    }
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 150px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        height: 150px;
    }
`;

const TitleContent = styled.div`
    z-index: 1;
    border: .3rem dotted ${COLOR.ferra};
    padding: 10px 40px;
    margin: auto;
    @media only screen and ${SCREEN_MEDIA.small} {
        border: .2rem dotted ${COLOR.ferra};
        padding: 8px 30px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        border: .2rem dotted ${COLOR.ferra};
        padding: 8px 30px;
    }
`;

const Title = styled(Label)`
    z-index: 1;
    width: 100%;
    font-size: 56px;
    @media only screen and ${SCREEN_MEDIA.small} {
        font-size: 32px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        font-size: 42px;
    }
`;

const Banner = (props) => {
    return (
        <ParallaxStyled
            className='valign-wrapper'
            image={<img alt="" src={props.img_path ? props.img_path : PathBgColours}/>}
            options={{responsiveThreshold: 0}}
            children= {
                <TitleContent>
                    <Title
                        text_color={props.title_color ? props.title_color : COLOR.ferra}
                        text_align='center'
                        font_family={FONT_FAMILY.unicorns}
                        position='relative'>
                        {props.title}
                    </Title>
                </TitleContent>
            }
            />
    );
}

Banner.propTypes = {
    img_path: PropTypes.string,
    title: PropTypes.string.isRequired,
    title_color: PropTypes.string
}

export default Banner;