import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextIcon from './texticon.jsx';


const AContent = styled.a`
    float: ${props => props.float ? props.float : 'left'}; 
    margin: ${props => props.margin ? props.margin : '0 0'};
    border: ${props => props.border ? props.border : 'none'};
    cursor: pointer;
`;


const TextButton = (props) => {
    var margin = '0 0'
    if (props.margin === 'auto' || props.margin === 'auto 0' || props.margin === '0 auto') {
        margin = props.margin
    }
    return (
        <AContent 
            className='valign-wrapper'
            border={props.border}
            href={props.href}
            target={props.target}
            float={props.float}
            margin={props.margin}
            onClick={props.onClick}>
            <TextIcon
                ic_path={props.ic_path}
                ic_color={props.ic_color}
                ic_size={props.ic_size}
                ic_align={props.ic_align}
                ic_top={props.ic_top}
                text_color={props.text_color}
                text_size={props.text_size}
                font_family={props.font_family}
                padding={props.padding}
                valign={props.valign}
                separation={props.separation}
                margin={ margin }>
                {props.children}
            </TextIcon>
        </AContent>
    );
}

TextButton.propTypes = {
    ic_path: PropTypes.string,
    ic_color: PropTypes.string,
    ic_size: PropTypes.string,
    ic_align: PropTypes.string,
    ic_top: PropTypes.string,
    text_color: PropTypes.string,
    text_size: PropTypes.string,
    font_family: PropTypes.string,
    border: PropTypes.string,
    padding: PropTypes.string,
    valign: PropTypes.bool,
    margin: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    separation: PropTypes.string,
    float: PropTypes.string, 
    onClick: PropTypes.func
}

export default TextButton;