import React, { useRef } from 'react'
import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import $ from 'jquery';
import gsap from 'gsap'
import { Col } from 'react-materialize';
import { COLOR, FLOAT, LABEL, SIZE, SCREEN_MEDIA, FONT_FAMILY } from '../../utils/constants.js';
import { Label } from '../atoms/label.jsx'
import Row from '../organism/row.jsx';
import TextIcon from '../atoms/texticon.jsx'

import PathDownwardIcon from '../../icons/ic_downward.svg'


const TitleRow = styled(Row)`
    cursor: pointer
`

const ChildrenContent = styled.div`
    overflow: hidden;
`


const Collapsible = forwardRef((props, ref) => {

    const contentRef = useRef()
    var isExpanded = true
    var childHeight = 0

    useImperativeHandle(ref, () => ({
		refresh: () => { handleCollapsing() }
	}));

    const handleCollapsing = () => {
        childHeight = $(contentRef.current).height()
        collapseContentWithAnimation()

        $(`#${ props.title_id }`).on("click", function() {
            if (isExpanded) {
                collapseContentWithAnimation()
            } else {
                expandContentWithAnimation()
            }
        })
    }

    const collapseContent = () => {
        $(contentRef.current).css('height', 0)
        $(`#${ props.icon_id }`).css('transform', 'rotate(0deg)')
        isExpanded = false
    }

    const collapseContentWithAnimation = () => {
        gsap.to(contentRef.current, { height: 0 })
        gsap.to($(`#${ props.icon_id }`), { rotation: 0 })
        isExpanded = false
    }

    const expandContentWithAnimation = () => {
        gsap.to(contentRef.current, { height: childHeight })
        gsap.to($(`#${ props.icon_id }`), { rotation: 180 })
        isExpanded = true
    }

    return (
        <div>
            <TitleRow
                id={ props.title_id }
                className='valign-wrapper'
                margin='20px 0px 0px 0px'>

                <Col s={10}>
                    <Label
                        text_size={ SIZE.title }
                        text_color={ COLOR.ferra }
                        font_family={ FONT_FAMILY.antipastoPro }>
                        { props.title }
                    </Label>
                </Col>
                <Col s={2}>
                    <TextIcon
                        ic_id={ props.icon_id }
                        ic_path={ PathDownwardIcon }
                        ic_size='18px'
                        ic_color={ COLOR.ferra }
                        float={ FLOAT.right }/>
                </Col>
            </TitleRow>
            
            <ChildrenContent ref={contentRef}>
                { props.children }
            </ChildrenContent>
        </div>
    )
})

Collapsible.propTypes = {
    title: PropTypes.string,
    title_id: PropTypes.string,
    icon_id: PropTypes.string,
}


export default Collapsible;