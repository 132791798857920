import React, { useState, useEffect } from 'react';
import $ from 'jquery'; 
import M from 'materialize-css';
import { Container, Col, Modal, RadioGroup } from 'react-materialize';
import styled from 'styled-components';
import { SIZE, LABEL, COLOR } from '../../utils/constants.js';
import { INVITATIONS } from '../../utils/invitations.js';
import Row from '../organism/row.jsx';
import Path from '../molecules/path.jsx';
import Button from '../atoms/button.jsx';
import Banner from '../organism/banner.jsx';
import Divider from '../atoms/divider';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Share from "yet-another-react-lightbox/plugins/share";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";


const BodyInvitations = () => {

    const [index, setIndex] = useState(-1);

    return(
        <div>
            <Banner title={LABEL.invitations}/>

            <Container>
                <Row
                    margin='0 0'>
                    <Path 
                        endpoint={LABEL.invitations}
                        margin='20px 0' />
                </Row>

                <Row>

                    <PhotoAlbum
                        layout="rows"
                        photos={INVITATIONS}
                        targetRowHeight={300}
                        onClick={({ index: current }) => setIndex(current)}
                    />

                    <Lightbox
                        index={index}
                        plugins={ [Share] }
                        slides={INVITATIONS}
                        open={index >= 0}
                        close={() => setIndex(-1)}
                    />
                </Row>

            </Container>

        </div>
    )
}

export default BodyInvitations;