import $ from 'jquery';

const capitalizeFirstLetter = (input) => {
    const arr = input.split(" ");

    //loop through each element of the array and capitalize the first letter.
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    //Join all the elements of the array back into a string 
    //using a blankspace as a separator 
    return arr.join(" ");
}

const isNumeric = (num) => {
    return !isNaN(num)
}

const getExtension = (pathObject) => {
    var pathString = pathObject.toString();
    return pathString.split('.').pop();
}

// Method to toggle visibility of a JavaScript document
const toggleVisibility = (document) => {
    $(document).toggle()
}

const hideElement = (document) => {
    $(document).hide()
}

const showElement = (document) => {
    $(document).show()
}

const stringToInt = (value) => {
    return parseInt(value, 0)
}

export {
    capitalizeFirstLetter,
    isNumeric,
    getExtension,
    toggleVisibility,
    hideElement,
    showElement,
    stringToInt
}