import React from 'react';
import PropTypes, { string } from 'prop-types';
import styled from 'styled-components';
import { COLOR, SIZE } from '../../utils/constants.js';
import CardContent from './cardcontent.jsx';


const Containter = styled.div`
    height: ${props => props.height ? props.height : ''};
    border: 1px solid ${COLOR.grayClear};
    margin: ${props => props.margin ? props.margin : ''};
    padding: 20px 20px 30px 20px;

    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02);
`;


const Card = (props) => {
    return (
        <Containter
            height={props.height}
            margin={props.margin} >
            <CardContent
                ic_path={props.ic_path}
                ic_height={props.ic_height}
                ic_color={props.ic_color}
                title={props.title}
                title_size={SIZE.title}
                title_color={props.title_color}
                title_separation={props.title_separation}
                descs={props.descs}
                desc_color={props.desc_color}
                descs_size={SIZE.title}
                desc_separation={props.desc_separation}
                alignment={props.alignment}
                separation={props.separation} />
        </Containter>
    );
}

Card.propTypes = {
    height: PropTypes.string,
    margin: PropTypes.string,
    ic_path: PropTypes.string,
    ic_height: PropTypes.string,
    ic_color: PropTypes.string,
    title: PropTypes.string,
    title_color: PropTypes.string,
    title_separation: PropTypes.string,
    descs: PropTypes.arrayOf(string),
    desc_color: PropTypes.string,
    desc_separation: PropTypes.string,
    alignment: PropTypes.string,
    separation: PropTypes.string
}

export default Card;