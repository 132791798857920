import React, { useRef, useEffect } from 'react';
import $ from 'jquery';
import PropTypes, { object } from 'prop-types';
import styled from 'styled-components';
import gsap from 'gsap';
import TextButton from '../atoms/textbutton.jsx';
import { COLOR, FONT_FAMILY, URL, SCREEN_MEDIA } from '../../utils/constants.js';
import Slide from './slide.jsx';


const SliderContent = styled.div`
    width: 100%;
	position: relative;
	overflow: hidden;
    margin: ${props => props.margin ? props.margin : '0 0'};
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 700px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        height: 700px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        height: 700px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        height: 900px;
    }
`;

const SliderListContent = styled.div`
    width: 100%;
	position: relative;
	overflow: hidden;
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 600px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        height: 600px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        height: 600px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        height: 800px;
    }
`;

const ButtonContent = styled.div`
    height: 50px;
    width: 100px;
    margin: 0 auto;
    position: relative;
    @media only screen and ${SCREEN_MEDIA.small} {
        top: 500px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        top: 500px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        top: 500px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        top: 700px;
    }
`;

const BubbleContent = styled.div`
    height: 50px;
    width: 250px;
    margin: 0 auto;
    position: relative;
    bottom: 0;
    z-index: 40;
    > div {
        display: block;
        height: 20px;
        width: 40px;
        float: left;
    }
`;

const Bubble = styled.span`
    position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	float: left;
	margin: 0px 14px;
`;

const BubbleUnder = styled(Bubble)`
    border: 1px solid ${COLOR.ferra};
    &:hover {
        cursor: pointer;
        background: ${COLOR.ferra};
    }
`;

const BubbleOver = styled(Bubble)`
    background: ${COLOR.ferra};
	opacity: 0;
    visibility: hidden;
`;

const Slider = (props) => {
    var bubbles_visible = props.showBubble ? 'visible' : 'hidden';

    // store a reference to the slide title
    const slide01Ref = useRef();
    const slide02Ref = useRef();
    const slide03Ref = useRef();
    const slide04Ref = useRef();
    const slide05Ref = useRef();
    const slide06Ref = useRef();
    const slideRefs = [slide01Ref, slide02Ref, slide03Ref, slide04Ref, slide05Ref, slide06Ref];

    const bubbleOver01Ref = useRef();
    const bubbleOver02Ref = useRef();
    const bubbleOver03Ref = useRef();
    const bubbleOver04Ref = useRef();
    const bubbleOver05Ref = useRef();
    const bubbleOver06Ref = useRef();
    const bubbleOverRefs = [bubbleOver01Ref, bubbleOver02Ref, bubbleOver03Ref, bubbleOver04Ref, bubbleOver05Ref, bubbleOver06Ref];

    // wait until DOM has been rendered
    useEffect(() => {
        const duration_slide = 5; // duration in secs
        const duration_trans = 0.5; // duration in secs

        const slides = $('#slider_content #slide');
        const bubblesUnder = $('#slider_content #bubble_under');

        const timeLine1 = gsap.timeline({repeat: -1, delay: 0});
        const timeLine2 = gsap.timeline({repeat: -1, delay: duration_slide * 1});
        const timeLine3 = gsap.timeline({repeat: -1, delay: duration_slide * 2});
        const timeLine4 = gsap.timeline({repeat: -1, delay: duration_slide * 3});
        const timeLine5 = gsap.timeline({repeat: -1, delay: duration_slide * 4});
        const timeLine6 = gsap.timeline({repeat: -1, delay: duration_slide * 5});
        const timeLines = [timeLine1, timeLine2, timeLine3, timeLine4, timeLine5, timeLine6];

        /************** SLIDER AUTOMÁTICO **************/
        for (var i=0; i < slideRefs.length; i++) {

            var slideRef = slideRefs[i];
            var slide = slides[i];
            var slideTitle = $(slide).find('p').eq(0);
            var slideDesc = $(slide).find('p').eq(1);

            timeLines[i].to(slideRef.current, {autoAlpha: 1, visibility: 'visible', duration: duration_trans}, 'begin');
            timeLines[i].to(slideTitle, {x: '18%', duration: duration_slide}, 'begin');
            timeLines[i].to(slideDesc, {x: '-10%', duration: duration_slide}, 'begin');
            timeLines[i].to(bubbleOverRefs[i].current, {autoAlpha:1, visibility:"visible", duration: duration_trans}, 'begin');

            timeLines[i].to(slideRef.current, {autoAlpha: 0, visibility: 'hidden', duration: duration_trans}, `begin+=${duration_slide}`);
            timeLines[i].to(slideRef.current, {duration: (duration_slide * (slideRefs.length - 1) ) - duration_trans}, `begin+=${duration_slide + duration_trans}`); // keeps the slide invisible up to its turn.
            timeLines[i].to(bubbleOverRefs[i].current, {autoAlpha:0, visibility:"hidden", duration: duration_trans}, `begin+=${duration_slide}`);
        }

        bubblesUnder.on("click", function() {
            var index = $('#slider_content #bubble_under').index( this ); // return the clicked index
            for (var j = 0; j< slideRefs.length; j++) {
                if (j === index) {
                    var timeLine = timeLines[j];
                    timeLine.restart();
                    setTimeout(function(){
                        timeLine.kill();
                    }, (duration_slide - duration_trans) * 1000);
                }
                else {
                    timeLines[j].kill();
                    gsap.to(slideRefs[j].current, {autoAlpha: 0, visibility: 'hidden', duration: duration_trans});
                    gsap.to(bubbleOverRefs[j].current, {autoAlpha: 0, visibility: 'hidden', duration: duration_trans});
                }
            }
        });
    });

    const slideList = props.slides_info?.map((slide_info, index) => {
        return (
            <Slide 
                key={index}
                ref={slideRefs[index]}
                bg_path={slide_info.bg_path}
                title={slide_info.title}
                description={slide_info.desc}
                layer_opacity={slide_info.layer_opacity}/>
        )
    })

    const bubbleList = props.slides_info?.map((slide_info, index) => 
        <div key={index}>
            <BubbleUnder id='bubble_under'/>
            <BubbleOver id='bubble_over' ref={bubbleOverRefs[index]}/>
        </div>
    )

    return (
        <SliderContent id='slider_content' margin={props.margin}>

            <SliderListContent>
                {slideList}

                <ButtonContent>
                <TextButton
                    margin='auto'
                    float='none'
                    text_size='30px'
                    text_color={COLOR.white}
                    font_family={FONT_FAMILY.antipastoProMedium}
                    border={`solid 3px ${COLOR.white}`}
                    href={ URL.thematics }>
                        VER
                </TextButton>
                </ButtonContent>
            </SliderListContent>

            <BubbleContent style={{visibility: bubbles_visible}} className='valign-wrapper'>
                {bubbleList}
            </BubbleContent>
        </SliderContent>
    );
}

Slider.propTypes = {
    showBubble: PropTypes.bool.isRequired,
    slides_info: PropTypes.arrayOf(object),
    margin: PropTypes.string
}


export default Slider;