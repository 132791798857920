import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ImgStyled = styled.img`
     width: ${ props => props.width ? props.width : 'none'};
     height: ${ props => props.height ? props.height : 'none'};
     display: ${ props => props.display ? props.display : 'none'};
     position: ${props => props.position ? props.position : 'none'};
     margin: ${props => props.margin ? props.margin : 'none'};
     border: ${props => props.border ? props.border : 'none'};
     transform: ${ props => props.transform ? props.transform : 'none' };
     top: ${ props => props.top ? props.top : 'none' };
`;

const Img = (props) => {
     return (
          <ImgStyled
               className={`responsive-img ${props.className} ${props.shadow}`}
               src={props.src}
               width={props.width}
               height={props.height}
               display={props.display}
               position={props.position}
               margin={props.margin}
               border={props.border}
               transform={props.transform}
               top={props.top}/>
     )
}

Img.propTypes = {
     className: PropTypes.string, //Must have className to apply styled component css properties.
     src: PropTypes.string,
     width: PropTypes.string,
     height: PropTypes.string,
     display: PropTypes.string,
     position: PropTypes.string,
     margin: PropTypes.string,
     border: PropTypes.string,
     shadow: PropTypes.string,
     transform: PropTypes.string,
     top: PropTypes.string
}

export default Img;