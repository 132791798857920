import { CATEGORY, THEMATIC } from './constants.js';

import PathImgCelebration001 from '../images_categories/celebrations/img_001.jpg';
import PathImgCelebration002 from '../images_categories/celebrations/img_002.jpg';
import PathImgCelebration003 from '../images_categories/celebrations/img_003.jpg';
import PathImgCelebration004 from '../images_categories/celebrations/img_004.jpg';
import PathImgCelebration005S00 from '../images_categories/celebrations/img_005_00.jpg';
import PathImgCelebration005S01 from '../images_categories/celebrations/img_005_01.jpg';
import PathImgCelebration005S02 from '../images_categories/celebrations/img_005_02.jpg';
import PathImgCelebration005S04 from '../images_categories/celebrations/img_005_04.jpg';
import PathImgCelebration006 from '../images_categories/celebrations/img_006.jpg';
import PathImgCelebration_008_001 from '../images_categories/celebrations/img_008_001.jpg';
import PathImgCelebration_008_002 from '../images_categories/celebrations/img_008_002.jpg';
import PathImgCelebration_008_004 from '../images_categories/celebrations/img_008_004.jpg';
import PathImgCelebration_009_001 from '../images_categories/celebrations/img_009_001.jpg';
import PathImgCelebration_009_003 from '../images_categories/celebrations/img_009_003.jpg';
import PathImgCelebration_010_001 from '../images_categories/celebrations/img_010_001.jpg';
import PathImgCelebration_010_002 from '../images_categories/celebrations/img_010_002.jpg';
import PathImgCelebration_010_003 from '../images_categories/celebrations/img_010_003.jpg';
import PathImgCelebration_011_001 from '../images_categories/celebrations/img_011_001.jpg';
import PathImgCelebration_011_002 from '../images_categories/celebrations/img_011_002.jpg';
import PathImgCelebration_011_003 from '../images_categories/celebrations/img_011_003.jpg';
import PathImgCelebration_011_007 from '../images_categories/celebrations/img_011_007.jpg';
import PathImgCelebration_012_001 from '../images_categories/celebrations/img_012_001.jpg';
import PathImgCelebration_012_002 from '../images_categories/celebrations/img_012_002.jpg';
import PathImgCelebration_013_001 from '../images_categories/celebrations/img_013_001.jpg';
import PathImgCelebration_013_002 from '../images_categories/celebrations/img_013_002.jpg';
import PathImgCelebration_013_003 from '../images_categories/celebrations/img_013_003.jpg';
import PathImgCelebration_014_001 from '../images_categories/celebrations/img_014_001.jpg';
import PathImgCelebration_014_004 from '../images_categories/celebrations/img_014_004.jpg';
import PathImgCelebration_014_005 from '../images_categories/celebrations/img_014_005.jpg';


const PICTURES_CELEBRATIONS = [
    {
        src: PathImgCelebration_011_007,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolAnniversary,
        title: THEMATIC.schoolAnniversary.title
    },
    {
        src: PathImgCelebration_011_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolAnniversary,
        title: THEMATIC.schoolAnniversary.title
    },
    {
        src: PathImgCelebration_011_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolAnniversary,
        title: THEMATIC.schoolAnniversary.title
    },
    {
        src: PathImgCelebration_011_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolAnniversary,
        title: THEMATIC.schoolAnniversary.title
    },
    {
        src: PathImgCelebration006,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title
    },
    {
        src: PathImgCelebration_013_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title
    },
    {
        src: PathImgCelebration_013_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title
    },
    {
        src: PathImgCelebration_013_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title
    },
    {
        src: PathImgCelebration_008_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title
    },
    {
        src: PathImgCelebration_008_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title
    },
    {
        src: PathImgCelebration_008_004,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title
    },
    {
        src: PathImgCelebration001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title
    },
    {
        src: PathImgCelebration005S00,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title
    },
    {
        src: PathImgCelebration005S01,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title
    },
    {
        src: PathImgCelebration005S02,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title
    },
    {
        src: PathImgCelebration005S04,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title
    },
    {
        src: PathImgCelebration_009_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title
    },
    {
        src: PathImgCelebration_009_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title
    },
    {
        src: PathImgCelebration_010_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title
    },
    {
        src: PathImgCelebration_010_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title
    },
    {
        src: PathImgCelebration_010_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title
    },
    {
        src: PathImgCelebration_014_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title
    },
    {
        src: PathImgCelebration_014_004,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title
    },
    {
        src: PathImgCelebration_014_005,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title
    },
    {
        src: PathImgCelebration004,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title
    },
    {
        src: PathImgCelebration_012_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title
    },
    {
        src: PathImgCelebration_012_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title
    },
    {
        src: PathImgCelebration002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title
    },
    {
        src: PathImgCelebration003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title
    },
]

export { PICTURES_CELEBRATIONS }