import React, { useState } from 'react';
import PropTypes, { string, object } from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { COLOR, SIZE, FONT_FAMILY } from '../../utils/constants.js';
import { Label } from '../atoms/label.jsx';
import { isNumeric, getExtension } from '../../utils/utils.js';
import Bullets from './bullets.jsx';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


const AContent = styled.a`
    height: ${props => props.height ? props.height : ''};
    margin: ${props => props.margin ? props.margin : '0 0'};
    padding: ${props => props.padding ? props.padding : '0 0'};
`;

const SVGStyled = styled(SVG)`
    height: ${props => props.height ? props.height : '35px'};
    fill: ${props => props.ic_color ? props.ic_color : COLOR.black};
    display: block;
    margin: ${props => props.alignment && props.alignment === 'center' ? 'auto' : 'none'};
`;

const Image = styled.img`
    height: ${props => props.height ? props.height : '35px'};
    display: block;
    margin: ${props => props.alignment && props.alignment === 'center' ? 'auto' : 'none'};
`;


const CardContent = (props) => {
    
    const [state, setState] = useState(true);
    const marginTitle = props.title_separation ? props.title_separation + ' 0 0 0' : '10px';
    const marginDesc = props.desc_separation ? props.desc_separation + ' 0 0 0' : '0px';
    const listDesc = props.descs?.map((desc, index) =>
            <Label
                key={index}
                text_color={props.desc_color ? props.desc_color : COLOR.secondary}
                text_size={props.descs_size ? props.descs_size : SIZE.body}
                text_align={props.alignment ? props.alignment : 'left'}
                font_family={FONT_FAMILY.unicorns}
                margin={marginDesc}>
                {desc}
            </Label>
        );

    const icExtension = getExtension(props.ic_path)

    const icon = (icExtension === 'svg' && props.ic_color) ? 
        <SVGStyled 
            src={props.ic_path}
            height={props.ic_height}
            ic_color={props.ic_color}
            alignment={props.alignment}/>
        :
        <Image 
            src={props.ic_path}
            height={props.ic_height}
            ic_color={props.ic_color}
            alignment={props.alignment}/>;
    
    
    if (isNumeric(props.title)) {
        return (
            <AContent 
                height={props.height}
                margin={props.margin} 
                padding={props.padding}
                href={props.href}>
                { icon }
                <CountUp 
                    start={0} 
                    end={parseInt(props.title)}
                    title_suffix={props.title_suffix}
                    redraw={true}
                    onEnd={() => {setState(false)}}
                    >
                    {({ countUpRef, start }) => (
                        <VisibilitySensor active={state} onChange={start} delayedCall>
                            {({isVisible}) => 
                                <Label
                                text_color={props.title_color ? props.title_color : COLOR.secondary}
                                text_size={props.title_size ? props.title_size : SIZE.headline}
                                text_align={props.alignment ? props.alignment : 'left'}
                                font_family={FONT_FAMILY.unicorns}
                                margin={marginTitle} >
                                    <span ref={countUpRef}/>
                                </Label>
                            }
                        </VisibilitySensor>
                    )}
                </CountUp>
                {listDesc}
                <Bullets items={props.bullet_items}/>
            </AContent>
        );
    }
    else {
        return (
            <AContent 
                height={props.height}
                margin={props.margin} 
                padding={props.padding}
                href={props.href}>
                { icon }
                <Label
                    text_color={props.title_color ? props.title_color : COLOR.secondary}
                    text_size={props.title_size ? props.title_size : SIZE.headline}
                    text_align={props.alignment ? props.alignment : 'left'}
                    font_family={FONT_FAMILY.unicorns}
                    margin={marginTitle}>
                    {props.title}
                </Label>
                {listDesc}
                <Bullets items={props.bullet_items}/>
            </AContent>
        );
    }
}

CardContent.propTypes = {
    height: PropTypes.string,
    ic_path: PropTypes.string.isRequired,
    ic_height: PropTypes.string,
    ic_color: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    title_size: PropTypes.string,
    title_color: PropTypes.string,
    title_separation: PropTypes.string,
    title_suffix: PropTypes.string,
    descs: PropTypes.arrayOf(string),
    descs_size: PropTypes.string,
    desc_color: PropTypes.string,
    desc_separation: PropTypes.string,
    bullets: PropTypes.arrayOf(string),
    bullet_items: PropTypes.arrayOf(object),
    bullet_size: PropTypes.string,
    bullet_ic_path: PropTypes.string,
    alignment: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string
}

export default CardContent;