import { hideElement, showElement } from '../utils/utils.js';

const mediaQuerySm = window.matchMedia('(min-width: 0px)');
const mediaQueryMd = window.matchMedia('(min-width: 600px)');
const mediaQueryLg = window.matchMedia('(min-width: 992px)');
const mediaQueryXLg = window.matchMedia('(min-width: 1200px)');

const EDIT_MODAL = {
    id: 'modalSketchEdit',
    title: 'Muchas felicidades a los ganadores del sorteo!'
}

const EDIT_IMAGE_MODAL = {
    id: 'modalSketchImageEdit',
    title: 'Image editing'
}

const getElementParams = (elementHeight, backgroundWidth) => {
    var topValue = getSketchBackgroundHeight() - elementHeight - getSketchTopExtra()
    var bottomValue = getSketchBackgroundHeight() - elementHeight
    var rightValue = backgroundWidth - getSketchRightExtra()
    var elementParams = {
        bounds: {left: -10, top: topValue, bottom: bottomValue, right: rightValue},
        pos: {x: 0, y: topValue}
    }
    return elementParams
}

const getElementFreeParams = (elementHeight, backgroundWidth) => {
    var topValue = 0
    var bottomValue = getSketchBackgroundHeight() - elementHeight
    var rightValue = backgroundWidth - getSketchRightExtra()
    var elementParams = {
        bounds: {left: -10, top: topValue, bottom: bottomValue, right: rightValue},
        pos: {x: 0, y: topValue}
    }
    return elementParams
}

const getSketchTopExtra = () => {
    if (mediaQueryXLg.matches) {
        return 200
    } else if (mediaQueryLg.matches) {
        return 160
    } else if (mediaQueryMd.matches) {
        return 140
    } else if (mediaQuerySm.matches) {
        return 100
    }
}

const getSketchRightExtra = () => {
    if (mediaQueryXLg.matches) {
        return 100
    } else if (mediaQueryLg.matches) {
        return 80
    } else if (mediaQueryMd.matches) {
        return 60
    } else if (mediaQuerySm.matches) {
        return 40
    }
}

const getSketchBackgroundHeight = () => {
    if (mediaQueryXLg.matches) {
        return 1000
    } else if (mediaQueryLg.matches) {
        return 800
    } else if (mediaQueryMd.matches) {
        return 600
    } else if (mediaQuerySm.matches) {
        return 400
    }
}

const getShimmerHeight = () => {
    if (mediaQueryXLg.matches) {
        return 540
    } else if (mediaQueryLg.matches) {
        return 415
    } else if (mediaQueryMd.matches) {
        return 290
    } else if (mediaQuerySm.matches) {
        return 165
    }
}

const getBackgroundFilledHeight = () => {
    if (mediaQueryXLg.matches) {
        return 650
    } else if (mediaQueryLg.matches) {
        return 500
    } else if (mediaQueryMd.matches) {
        return 350
    } else if (mediaQuerySm.matches) {
        return 200
    }
}

const getBackgroundCircleHeight = () => {
    if (mediaQueryXLg.matches) {
        return 560
    } else if (mediaQueryLg.matches) {
        return 430
    } else if (mediaQueryMd.matches) {
        return 300
    } else if (mediaQuerySm.matches) {
        return 170
    }
}

const getBackgroundCircleImageResultHeight = () => {
    if (mediaQueryXLg.matches) {
        return 500
    } else if (mediaQueryLg.matches) {
        return 380
    } else if (mediaQueryMd.matches) {
        return 265
    } else if (mediaQuerySm.matches) {
        return 152
    }
}

const getBalloonHeight = () => {
    if (mediaQueryXLg.matches) {
        return 100
    } else if (mediaQueryLg.matches) {
        return 80
    } else if (mediaQueryMd.matches) {
        return 60
    } else if (mediaQuerySm.matches) {
        return 40
    }
}

const getBalloonsGroup4Height = () => {
    if (mediaQueryXLg.matches) {
        return 140
    } else if (mediaQueryLg.matches) {
        return 110
    } else if (mediaQueryMd.matches) {
        return 80
    } else if (mediaQuerySm.matches) {
        return 50
    }
}

const getArchHeight = () => {
    if (mediaQueryXLg.matches) {
        return 610
    } else if (mediaQueryLg.matches) {
        return 470
    } else if (mediaQueryMd.matches) {
        return 330
    } else if (mediaQuerySm.matches) {
        return 190
    }
}

const getPodiumHeight = () => {
    if (mediaQueryXLg.matches) {
        return 120
    } else if (mediaQueryLg.matches) {
        return 95
    } else if (mediaQueryMd.matches) {
        return 70
    } else if (mediaQuerySm.matches) {
        return 45
    }
}

const getPedestalHeight = () => {
    if (mediaQueryXLg.matches) {
        return 270
    } else if (mediaQueryLg.matches) {
        return 210
    } else if (mediaQueryMd.matches) {
        return 150
    } else if (mediaQuerySm.matches) {
        return 90
    }
}

const getPedestalLongHeight = () => {
    if (mediaQueryXLg.matches) {
        return 310
    } else if (mediaQueryLg.matches) {
        return 245
    } else if (mediaQueryMd.matches) {
        return 180
    } else if (mediaQuerySm.matches) {
        return 115
    }
}

const getTableHeight = () => {
    if (mediaQueryXLg.matches) {
        return 295
    } else if (mediaQueryLg.matches) {
        return 230
    } else if (mediaQueryMd.matches) {
        return 165
    } else if (mediaQuerySm.matches) {
        return 100
    }
}

const getChairKingHeight = () => {
    if (mediaQueryXLg.matches) {
        return 610
    } else if (mediaQueryLg.matches) {
        return 470
    } else if (mediaQueryMd.matches) {
        return 330
    } else if (mediaQuerySm.matches) {
        return 190
    }
}

const getChairKingSmallHeight = () => {
    if (mediaQueryXLg.matches) {
        return 345
    } else if (mediaQueryLg.matches) {
        return 270
    } else if (mediaQueryMd.matches) {
        return 195
    } else if (mediaQuerySm.matches) {
        return 120
    }
}

const getNumberHeight = () => {
    if (mediaQueryXLg.matches) {
        return 270
    } else if (mediaQueryLg.matches) {
        return 210
    } else if (mediaQueryMd.matches) {
        return 150
    } else if (mediaQuerySm.matches) {
        return 90
    }
}

const updateElementCount = (newValue, refList) => {
    for (var i=0; i < refList.length; i++) {
        if (i < newValue) {
            showElement(refList[i].current)
        } else {
            hideElement(refList[i].current)
        }
    }
}

export {
    updateElementCount,
    getElementParams,
    getElementFreeParams,
    getSketchBackgroundHeight,
    getShimmerHeight,
    getBackgroundFilledHeight,
    getBackgroundCircleHeight,
    getBackgroundCircleImageResultHeight,
    getBalloonHeight,
    getBalloonsGroup4Height,
    getArchHeight,
    getPodiumHeight,
    getPedestalHeight,
    getPedestalLongHeight,
    getTableHeight,
    getChairKingHeight,
    getChairKingSmallHeight,
    getNumberHeight,
    EDIT_MODAL,
    EDIT_IMAGE_MODAL
}