import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import $ from 'jquery';
import jQuery from 'jquery';
import M from 'materialize-css';
import { Container, Col, Modal } from 'react-materialize';
import { COLOR, LABEL, SIZE, SCREEN_MEDIA, FONT_FAMILY } from '../../utils/constants.js';
import Draggable from 'react-draggable';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';
import 'react-image-picker-editor/dist/index.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Row from '../organism/row.jsx';
import Banner from '../organism/banner.jsx';
import Path from '../molecules/path.jsx';
import LabelCounterButtons from '../molecules/labelcounterbuttons.jsx';
import TextButton from '../atoms/textbutton.jsx';
import Button from '../atoms/button.jsx';
import { Label } from '../atoms/label.jsx';
import Collapsible from '../organism/collapsible.jsx'
import { stringToInt } from '../../utils/utils.js';
import {
    updateElementCount,
    getElementParams,
    getElementFreeParams,
    getSketchBackgroundHeight,
    getShimmerHeight,
    getBackgroundFilledHeight,
    getBackgroundCircleHeight,
    getBackgroundCircleImageResultHeight,
    getBalloonHeight,
    getBalloonsGroup4Height,
    getArchHeight,
    getPodiumHeight,
    getPedestalHeight,
    getPedestalLongHeight,
    getTableHeight,
    getChairKingHeight,
    getChairKingSmallHeight,
    getNumberHeight,
    EDIT_MODAL,
    EDIT_IMAGE_MODAL
} from '../../utils/managersketch.js';

import PathSketchBackground from '../../images_sketch/img_sketch_background.jpg';

import PathShimmerSilver from '../../images_sketch/img_shimmer_silver.png'
import PathShimmerGolden from '../../images_sketch/img_shimmer_golden.png'
import PathShimmerColors from '../../images_sketch/img_shimmer_colors.png'

import PathBackgroundArchPink from '../../images_sketch/img_background_arch_pink.png';
import PathBackgroundArchSky from '../../images_sketch/img_background_arch_sky.png';
import PathBackgroundArchGreen from '../../images_sketch/img_background_arch_green.png';
import PathBackgroundSquarePink from '../../images_sketch/img_background_square_pink.png';
import PathBackgroundSquareSky from '../../images_sketch/img_background_square_sky.png';
import PathBackgroundSquareGreen from '../../images_sketch/img_background_square_green.png';
import PathBackgroundCircleEmpty from '../../images_sketch/img_background_circle_empty.png';
import PathBackgroundCircleCurtain from '../../images_sketch/img_background_circle_curtain.png';

import PathBalloonsGroup4Red from '../../images_sketch/img_balloons_group4_red.png';
import PathBalloonRed from '../../images_sketch/img_balloon_red.png';
import PathBalloonsGroup4Golden from '../../images_sketch/img_balloons_group4_golden.png';
import PathBalloonGolden from '../../images_sketch/img_balloon_golden.png';
import PathBalloonsGroup4White from '../../images_sketch/img_balloons_group4_white.png';
import PathBalloonWhite from '../../images_sketch/img_balloon_white.png';
import PathBalloonTransparent from '../../images_sketch/img_balloon_transparent.png';

import PathArchStd from '../../images_sketch/img_arch_std.png'
import PathArchCornered from '../../images_sketch/img_arch_cornered.png'
import PathArchInfinite from '../../images_sketch/img_arch_infinite.png'

import PathPodiumCircle from '../../images_sketch/img_podium_circle.png';

import PathPedestalCylinder from '../../images_sketch/img_pedestal_cylinder.png';
import PathPedestalSquare from '../../images_sketch/img_pedestal_square.png';
import PathPedestalSquareTrans from '../../images_sketch/img_pedestal_square_trans.png';
import PathPedestalPilar from '../../images_sketch/img_pedestal_pilar.png'

import PathTableIronSquare from '../../images_sketch/img_table_iron_square.png'
import PathTableIronHourglass from '../../images_sketch/img_table_iron_hourglass.png'

import PathChairKing from '../../images_sketch/img_chair_king.png';
import PathChairKingSmall from '../../images_sketch/img_chair_king_small.png';

import PathNumberZero from '../../images_sketch/img_number_zero.png';
import PathNumberOne from '../../images_sketch/img_number_one.png';
import PathNumberThree from '../../images_sketch/img_number_three.png';
import PathNumberFive from '../../images_sketch/img_number_five.png';

import PathCircledEditIc from '../../icons/ic_circled_edit.svg'

const SketchBackground = styled.div`
    background-image: url(${PathSketchBackground});
    width: 100%;
    height: ${ `${getSketchBackgroundHeight()}px; `}
    // Cover and center background image: //
    background-position: 50% 50%; /*El cuadro ajustado se puede centrar horizontal y verticalmente*/
    background-size: cover; /*El cuadro de la imagen se ajusta a la dimensión más pequeña del div con el objetivo de cubrirla*/
    background-repeat: no-repeat;
`;

const ShimmerImg = styled.img`
    position: absolute;
    height: ${ `${getShimmerHeight()}px; `}
`;

const BackgroundFilledImg = styled.img`
    position: absolute;
    height: ${ `${getBackgroundFilledHeight()}px; `}
`;

const BackgroundCircleImageContent = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    position: absolute;
`;

const BackgroundCircleEmptyImg = styled.img`
    position: absolute;
    height: ${ `${getBackgroundCircleHeight()}px; `}
`;

const BackgrounCircleImageResultImg = styled.img`
    width: ${ `${getBackgroundCircleImageResultHeight()}px;` }
    height: ${ `${getBackgroundCircleImageResultHeight()}px;` }
    border-radius: 50%;
    object-fit: cover;
`;

const BackgroundCircleCurtainImg = styled.img`
    position: absolute;
    height: ${ `${getBackgroundCircleHeight()}px; `}
`;

const BalloonImg = styled.img`
    position: absolute;
    height: ${ `${getBalloonHeight()}px; `}
`;

const BalloonsGroup4Img = styled.img`
    position: absolute;
    height: ${ `${getBalloonsGroup4Height()}px; `}
`;

const ArchImg = styled.img`
    position: absolute;
    height: ${ `${getArchHeight()}px; `}
`;

const PodiumImg = styled.img`
    position: absolute;
    height: ${ `${getPodiumHeight()}px; `}
`;

const PedestalImg = styled.img`
    position: absolute;
    height: ${ `${getPedestalHeight()}px; `}
`;

const PedestalLongImg = styled.img`
    position: absolute;
    height: ${ `${getPedestalLongHeight()}px; `}
`;

const TableImg = styled.img`
    position: absolute;
    height: ${ `${getTableHeight()}px; `}
`;

const ChairKingImg = styled.img`
    position: absolute;
    height: ${ `${getChairKingHeight()}px; `}
`;

const ChairKingSmallImg = styled.img`
    position: absolute;
    height: ${ `${getChairKingSmallHeight()}px; `}
`;

const NumberZeroImg = styled.img`
    position: absolute;
    height: ${ `${getNumberHeight()}px; `}
`;

const NumberOneImg = styled.img`
    position: absolute;
    height: ${ `${getNumberHeight()}px; `}
`;

const NumberThreeImg = styled.img`
    position: absolute;
    height: ${ `${getNumberHeight()}px; `}
`;

const NumberFiveImg = styled.img`
    position: absolute;
    height: ${ `${getNumberHeight()}px; `}
`;

const ModalStyled = styled(Modal)`
    width: 94%;
    height: 100%;
`;

var isWindowFirstLoad = true


const BodySketch = () => {

    var totalElementList = new Array()
    let numberOfClicks = 0;
    var currentZIndex = -1;

    const handleElementZIndex = (elementRef) => {
        currentZIndex++;
        console.log(`handleElementZIndex: ${currentZIndex}`)
        $(elementRef.current).css('z-index', currentZIndex)
        $(elementRef.current).unbind('click')
        $(elementRef.current).unbind('touchstart')
        $(elementRef.current).on('click touchstart', function (e) {
            e.preventDefault()
            numberOfClicks += 1;
            if (numberOfClicks === 3) {
                numberOfClicks = 0;
                decreaseZIndex(elementRef)
            } else if (numberOfClicks === 2) {
                secondsResetClick(elementRef, 1);
            } else if (numberOfClicks === 1) {
                secondsResetClick(elementRef, 2);
            }
        });
    }

    function secondsResetClick(elementRef, seconds) {
        setTimeout(function() {
            if (numberOfClicks == 2) {
                increaseZIndex(elementRef)
            }
            numberOfClicks = 0;
        }, seconds*200)
    }

    const increaseZIndex = (selectedElementRef) => {
        var zIndexString = $(selectedElementRef.current).css('z-index')
        var zIndex = stringToInt(zIndexString)

        for (var i = 0; i < totalElementList.length; i++) {
            const elementRef = totalElementList[i]
            const elementIsVisible = $(elementRef.current).is(":visible")
            const elementZIndexString = $(elementRef.current).css('z-index')
            const elementZIndex = stringToInt(elementZIndexString)

            if (elementIsVisible && zIndex <= elementZIndex && selectedElementRef != elementRef) {
                var increasedZIndex = elementZIndex + 1
                $(selectedElementRef.current).css('z-index', increasedZIndex)
                toast(`Índice de capa aumentado`)
                return false
            }
        }
    }

    const decreaseZIndex = (selectedElementRef) => {
        var zIndexString = $(selectedElementRef.current).css('z-index')
        var zIndex = stringToInt(zIndexString)

        for (var i = totalElementList.length - 1; i >= 0; i--) {
            const elementRef = totalElementList[i]
            const elementIsVisible = $(elementRef.current).is(":visible")
            const elementZIndexString = $(elementRef.current).css('z-index')
            const elementZIndex = stringToInt(elementZIndexString)
            
            if (elementIsVisible && zIndex >= elementZIndex && selectedElementRef != elementRef) {
                var decreasedZIndex = elementZIndex - 1
                $(selectedElementRef.current).css('z-index', decreasedZIndex)
                toast(`Índice de capa aumentado`)
                return false
            }
        }
    }


    const shimmerParams = getElementParams(getShimmerHeight(), $(window).width())
    const shimmerSilverRefList = [useRef(), useRef(), useRef()]
    const shimmerSilverDraggableList = shimmerSilverRefList.map((shimmerSilverRef, index) => {
        totalElementList.push(shimmerSilverRef)
        const shimmerSilverImg =
            <ShimmerImg
                draggable={false}
                ref={shimmerSilverRef}
                src={PathShimmerSilver}/>
            handleElementZIndex(shimmerSilverRef)
        return (
            <Draggable
                key={index}
                bounds={ shimmerParams.bounds }
                defaultPosition={ shimmerParams.pos }>
                { shimmerSilverImg }
            </Draggable>
        )
    })
    const updateShimmerSilverCount = (newValue) => {
        updateElementCount(newValue, shimmerSilverRefList)
    }

    const shimmerGoldenRefList = [useRef(), useRef(), useRef()]
    const shimmerGoldenDraggableList = shimmerGoldenRefList.map((shimmerGoldenRef, index) => {
        totalElementList.push(shimmerGoldenRef)
        const shimmerGoldenImg =
            <ShimmerImg
                draggable={false}
                ref={shimmerGoldenRef}
                src={PathShimmerGolden}/>
            handleElementZIndex(shimmerGoldenRef)
        return (
            <Draggable
                key={index}
                bounds={ shimmerParams.bounds }
                defaultPosition={ shimmerParams.pos }>
                { shimmerGoldenImg }
            </Draggable>
        )
    })
    const updateShimmerGoldenCount = (newValue) => {
        updateElementCount(newValue, shimmerGoldenRefList)
    }


    const shimmerColorsRefList = [useRef(), useRef(), useRef()]
    const shimmerColorsDraggableList = shimmerColorsRefList.map((shimmerColorsRef, index) => {
        totalElementList.push(shimmerColorsRef)
        const shimmerColorsImg =
            <ShimmerImg
                draggable={false}
                ref={shimmerColorsRef}
                src={PathShimmerColors}/>
            handleElementZIndex(shimmerColorsRef)
        return (
            <Draggable
                key={index}
                bounds={ shimmerParams.bounds }
                defaultPosition={ shimmerParams.pos }>
                { shimmerColorsImg }
            </Draggable>
        )
    })
    const updateShimmerColorsCount = (newValue) => {
        updateElementCount(newValue, shimmerColorsRefList)
    }


    const backgroundFilledParams = getElementParams(getBackgroundFilledHeight(), $(window).width())

    const backgroundArchPinkRefList = [useRef(), useRef(), useRef()]
    const backgroundArchPinkDraggableList = backgroundArchPinkRefList.map((bgArchPinkRef, index) => {
        totalElementList.push(bgArchPinkRef)
        const backgroundArchPinkImg =
            <BackgroundFilledImg
                draggable={false}
                ref={bgArchPinkRef}
                src={PathBackgroundArchPink}/>
            handleElementZIndex(bgArchPinkRef)
        return (
            <Draggable
                key={index}
                bounds={ backgroundFilledParams.bounds }
                defaultPosition={ backgroundFilledParams.pos }>
                { backgroundArchPinkImg }
            </Draggable>
        )
    })
    const updateBackgroundArchPinkCount = (newValue) => {
        updateElementCount(newValue, backgroundArchPinkRefList)
    }


    const backgroundArchSkyRefList = [useRef(), useRef(), useRef()]
    const backgroundArchSkyDraggableList = backgroundArchSkyRefList.map((bgArchSkyRef, index) => {
        totalElementList.push(bgArchSkyRef)
        const backgroundArchSkyImg =
            <BackgroundFilledImg
                draggable={false}
                ref={bgArchSkyRef}
                src={PathBackgroundArchSky}/>
            handleElementZIndex(bgArchSkyRef)
        return (
            <Draggable
                key={index}
                bounds={ backgroundFilledParams.bounds }
                defaultPosition={ backgroundFilledParams.pos }>
                { backgroundArchSkyImg }
            </Draggable>
        )
    })
    const updateBackgroundArchSkyCount = (newValue) => {
        updateElementCount(newValue, backgroundArchSkyRefList)
    }


    const backgroundArchGreenRefList = [useRef(), useRef(), useRef()]
    const backgroundArchGreenDraggableList = backgroundArchGreenRefList.map((bgArchGreenRef, index) => {
        totalElementList.push(bgArchGreenRef)
        const backgroundArchGreenImg =
            <BackgroundFilledImg
                draggable={false}
                ref={bgArchGreenRef}
                src={PathBackgroundArchGreen}/>
            handleElementZIndex(bgArchGreenRef)
        return (
            <Draggable
                key={index}
                bounds={ backgroundFilledParams.bounds }
                defaultPosition={ backgroundFilledParams.pos }>
                { backgroundArchGreenImg }
            </Draggable>
        )
    })
    const updateBackgroundArchGreenCount = (newValue) => {
        updateElementCount(newValue, backgroundArchGreenRefList)
    }


    const backgroundSquarePinkRefList = [useRef(), useRef(), useRef()]
    const backgroundSquarePinkDraggableList = backgroundSquarePinkRefList.map((bgSquarePinkRef, index) => {
        totalElementList.push(bgSquarePinkRef)
        const backgroundSquarePinkImg =
            <BackgroundFilledImg
                draggable={false}
                ref={bgSquarePinkRef}
                src={PathBackgroundSquarePink}/>
            handleElementZIndex(bgSquarePinkRef)
        return (
            <Draggable
                key={index}
                bounds={ backgroundFilledParams.bounds }
                defaultPosition={ backgroundFilledParams.pos }>
                { backgroundSquarePinkImg }
            </Draggable>
        )
    })
    const updateBackgroundSquarePinkCount = (newValue) => {
        updateElementCount(newValue, backgroundSquarePinkRefList)
    }




    const backgroundSquareSkyRefList = [useRef(), useRef(), useRef()]
    const backgroundSquareSkyDraggableList = backgroundSquareSkyRefList.map((bgSquareSkyRef, index) => {
        totalElementList.push(bgSquareSkyRef)
        const backgroundSquareSkyImg =
            <BackgroundFilledImg
                draggable={false}
                ref={bgSquareSkyRef}
                src={PathBackgroundSquareSky}/>
            handleElementZIndex(bgSquareSkyRef)
        return (
            <Draggable
                key={index}
                bounds={ backgroundFilledParams.bounds }
                defaultPosition={ backgroundFilledParams.pos }>
                { backgroundSquareSkyImg }
            </Draggable>
        )
    })
    const updateBackgroundSquareSkyCount = (newValue) => {
        updateElementCount(newValue, backgroundSquareSkyRefList)
    }



    const backgroundSquareGreenRefList = [useRef(), useRef(), useRef()]
    const backgroundSquareGreenDraggableList = backgroundSquareGreenRefList.map((bgSquareGreenRef, index) => {
        totalElementList.push(bgSquareGreenRef)
        const backgroundSquareGreenImg =
            <BackgroundFilledImg
                draggable={false}
                ref={bgSquareGreenRef}
                src={PathBackgroundSquareGreen}/>
            handleElementZIndex(bgSquareGreenRef)
        return (
            <Draggable
                key={index}
                bounds={ backgroundFilledParams.bounds }
                defaultPosition={ backgroundFilledParams.pos }>
                { backgroundSquareGreenImg }
            </Draggable>
        )
    })
    const updateBackgroundSquareGreenCount = (newValue) => {
        updateElementCount(newValue, backgroundSquareGreenRefList)
    }


    const backgroundCircleParams = getElementParams(getBackgroundCircleHeight(), $(window).width())
    const backgroundCircleEmptyImg = 
        <BackgroundCircleEmptyImg
            draggable={false}
            src={PathBackgroundCircleEmpty}/>

    
    const backgrounCircleImageResultRef = useRef();
    const backgrounCircleImageResult =
        <BackgrounCircleImageResultImg
            draggable={false}
            ref={backgrounCircleImageResultRef}/>


    const backgroundCircleImageContentRef = useRef()
    totalElementList.push(backgroundCircleImageContentRef)
    const backgroundCircleImageContent =
        <BackgroundCircleImageContent
            ref={backgroundCircleImageContentRef}>
            { backgroundCircleEmptyImg }
            { backgrounCircleImageResult }
        </BackgroundCircleImageContent>
        handleElementZIndex(backgroundCircleImageContentRef)

    const backgrounCircleDraggableRef = useRef()
    const backgrounCircleImagDraggabble =
    <Draggable
        ref={ backgrounCircleDraggableRef }
        bounds={ backgroundCircleParams.bounds }
        defaultPosition={ backgroundCircleParams.pos }>
        { backgroundCircleImageContent }
    </Draggable>


    const imagePickerConfig = {
        borderRadius: '76px',
        language: 'en',
        width: '152px',
        height: '152px',
        objectFit: 'cover',
        compressInitial: 50,
        };
    const imagePickerPreviewImg = 
        <ReactImagePickerEditor
            draggable={false}
            config={imagePickerConfig}
            imageChanged={ (newDataUri) => {
                $(backgrounCircleImageResultRef.current).attr("src", newDataUri);
            }}/>

    const backgroundCircleImageContentRefList = [backgroundCircleImageContentRef]

    const updateBackgroundCircleImageCount = (newValue) => {
        if (!isWindowFirstLoad && newValue > 0) {
            editImageModal.open()
        }
        updateElementCount(newValue, backgroundCircleImageContentRefList)
    }



    const backgroundCircleCurtainRef = useRef()
    totalElementList.push(backgroundCircleCurtainRef)
    const backgroundCircleCurtainImg =
        <BackgroundCircleCurtainImg
            draggable={false}
            ref={backgroundCircleCurtainRef}
            src={PathBackgroundCircleCurtain}/>
        handleElementZIndex(backgroundCircleCurtainRef)

    const backgroundCircleCurtainRefList = [backgroundCircleCurtainRef]

    const updateBackgroundCircleCurtainCount = (newValue) => {
        updateElementCount(newValue, backgroundCircleCurtainRefList)
    }





    const balloonParams = getElementFreeParams(getBalloonHeight(), $(window).width())

    const balloonGoldenRefList = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]
    const balloonGoldenDraggableList = balloonGoldenRefList.map((ballonGoldenRef, index) => {
        totalElementList.push(ballonGoldenRef)
        const ballonGoldenImg =
            <BalloonImg
                draggable={false}
                ref={ballonGoldenRef}
                src={PathBalloonGolden}/>
            handleElementZIndex(ballonGoldenRef)
        return (
            <Draggable
                key={index}
                bounds={ balloonParams.bounds }
                defaultPosition={ balloonParams.pos }>
                { ballonGoldenImg }
            </Draggable>
        )
    })

    const updateBalloonGoldenCount = (newValue) => {
        updateElementCount(newValue, balloonGoldenRefList)
    }



    const balloonRedRefList = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]
    const balloonRedDraggableList = balloonRedRefList.map((ballonRedRef, index) => {
        totalElementList.push(ballonRedRef)
        const ballonRedImg =
            <BalloonImg
                draggable={false}
                ref={ballonRedRef}
                src={PathBalloonRed}/>
            handleElementZIndex(ballonRedRef)
        return (
            <Draggable
                key={index}
                bounds={ balloonParams.bounds }
                defaultPosition={ balloonParams.pos }>
                { ballonRedImg }
            </Draggable>
        )
    })

    const updateBalloonRedCount = (newValue) => {
        updateElementCount(newValue, balloonRedRefList)
    }




    const balloonWhiteRefList = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]
    const balloonWhiteDraggableList = balloonWhiteRefList.map((ballonWhiteRef, index) => {
        totalElementList.push(ballonWhiteRef)
        const ballonWhiteImg =
            <BalloonImg
                draggable={false}
                ref={ballonWhiteRef}
                src={PathBalloonWhite}/>
            handleElementZIndex(ballonWhiteRef)
        return (
            <Draggable
                key={index}
                bounds={ balloonParams.bounds }
                defaultPosition={ balloonParams.pos }>
                { ballonWhiteImg }
            </Draggable>
        )
    })

    const updateBalloonWhiteCount = (newValue) => {
        updateElementCount(newValue, balloonWhiteRefList)
    }



    const balloonTransparentRefList = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]
    const balloonTransparentDraggableList = balloonTransparentRefList.map((ballonTransparentRef, index) => {
        totalElementList.push(ballonTransparentRef)
        const ballonTransparentImg =
            <BalloonImg
                draggable={false}
                ref={ballonTransparentRef}
                src={PathBalloonTransparent}/>
            handleElementZIndex(ballonTransparentRef)
        return (
            <Draggable
                key={index}
                bounds={ balloonParams.bounds }
                defaultPosition={ balloonParams.pos }>
                { ballonTransparentImg }
            </Draggable>
        )
    })

    const updateBalloonTransparentCount = (newValue) => {
        updateElementCount(newValue, balloonTransparentRefList)
    }




    const balloonsGroup4Params = getElementFreeParams(getBalloonsGroup4Height(), $(window).width())

    const balloonsGroup4GoldenRefList = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]
    const balloonsGroup4GoldenDraggableList = balloonsGroup4GoldenRefList.map((ballonsGroup4GoldenRef, index) => {
        totalElementList.push(ballonsGroup4GoldenRef)
        const ballonsGroup4GoldenImg =
            <BalloonsGroup4Img
                draggable={false}
                ref={ballonsGroup4GoldenRef}
                src={PathBalloonsGroup4Golden}/>
            handleElementZIndex(ballonsGroup4GoldenRef)
        return (
            <Draggable
                key={index}
                bounds={ balloonsGroup4Params.bounds }
                defaultPosition={ balloonsGroup4Params.pos }>
                { ballonsGroup4GoldenImg }
            </Draggable>
        )
    })

    const updateBalloonsGroup4GoldenCount = (newValue) => {
        updateElementCount(newValue, balloonsGroup4GoldenRefList)
    }




    const balloonsGroup4RedRefList = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]
    const balloonsGroup4RedDraggableList = balloonsGroup4RedRefList.map((ballonsGroup4RedRef, index) => {
        totalElementList.push(ballonsGroup4RedRef)
        const ballonsGroup4RedImg =
            <BalloonsGroup4Img
                draggable={false}
                ref={ballonsGroup4RedRef}
                src={PathBalloonsGroup4Red}/>
            handleElementZIndex(ballonsGroup4RedRef)
        return (
            <Draggable
                key={index}
                bounds={ balloonsGroup4Params.bounds }
                defaultPosition={ balloonsGroup4Params.pos }>
                { ballonsGroup4RedImg }
            </Draggable>
        )
    })

    const updateBalloonsGroup4RedCount = (newValue) => {
        updateElementCount(newValue, balloonsGroup4RedRefList)
    }




    const balloonsGroup4WhiteRefList = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]

    const balloonsGroup4WhiteDraggableList = balloonsGroup4WhiteRefList.map((ballonsGroup4WhiteRef, index) => {
        totalElementList.push(ballonsGroup4WhiteRef)
        const ballonsGroup4WhiteImg =
            <BalloonsGroup4Img
                draggable={false}
                ref={ballonsGroup4WhiteRef}
                src={PathBalloonsGroup4White}/>
            handleElementZIndex(ballonsGroup4WhiteRef)
        return (
            <Draggable
                key={index}
                bounds={ balloonsGroup4Params.bounds }
                defaultPosition={ balloonsGroup4Params.pos }>
                { ballonsGroup4WhiteImg }
            </Draggable>
        )
    })

    const updateBalloonsGroup4WhiteCount = (newValue) => {
        updateElementCount(newValue, balloonsGroup4WhiteRefList)
    }




    const archParams = getElementParams(getArchHeight(), $(window).width())

    const archStdRefList = [useRef(), useRef()]
    const archStfDraggableList = archStdRefList.map((archStdRef, index) => {
        totalElementList.push(archStdRef)
        const archStdImg =
            <ArchImg
                draggable={false}
                ref={archStdRef}
                src={PathArchStd}/>
            handleElementZIndex(archStdRef)
        return (
            <Draggable
                key={index}
                bounds={ archParams.bounds }
                defaultPosition={ archParams.pos }>
                { archStdImg }
            </Draggable>
        )
    })

    const updateArchStdCount = (newValue) => {
        updateElementCount(newValue, archStdRefList)
    }




    const archCorneredRefList = [useRef(), useRef()]
    const archCorneredDraggableList = archCorneredRefList.map((archCorneredRef, index) => {
        totalElementList.push(archCorneredRef)
        const archCorneredImg =
            <ArchImg
                draggable={false}
                ref={archCorneredRef}
                src={PathArchCornered}/>
            handleElementZIndex(archCorneredRef)
        return (
            <Draggable
                key={index}
                bounds={ archParams.bounds }
                defaultPosition={ archParams.pos }>
                { archCorneredImg }
            </Draggable>
        )
    })

    const updateArchCorneredCount = (newValue) => {
        updateElementCount(newValue, archCorneredRefList)
    }




    const archInfiniteRefList = [useRef(), useRef()]
    const archInfiniteDraggableList = archInfiniteRefList.map((archInfiniteRef, index) => {
        totalElementList.push(archInfiniteRef)
        const archInfiniteImg =
            <ArchImg
                draggable={false}
                ref={archInfiniteRef}
                src={PathArchInfinite}/>
            handleElementZIndex(archInfiniteRef)
        return (
            <Draggable
                key={index}
                bounds={ archParams.bounds }
                defaultPosition={ archParams.pos }>
                { archInfiniteImg }
            </Draggable>
        )
    })

    const updateArchInfiniteCount = (newValue) => {
        updateElementCount(newValue, archInfiniteRefList)
    }




    const chairKingParams = getElementParams(getChairKingHeight(), $(window).width())

    const chairKingRefList = [useRef()]
    const chairKingDraggableList = chairKingRefList.map((chairKingRef, index) => {
        totalElementList.push(chairKingRef)
        const chairKingImg =
            <ChairKingImg
                draggable={false}
                ref={chairKingRef}
                src={PathChairKing}/>
            handleElementZIndex(chairKingRef)
        return (
            <Draggable
                key={index}
                bounds={ chairKingParams.bounds }
                defaultPosition={ chairKingParams.pos }>
                { chairKingImg }
            </Draggable>
        )
    })

    const updateChairKingCount = (newValue) => {
        updateElementCount(newValue, chairKingRefList)
    }



    const chairKingSmallParams = getElementParams(getChairKingSmallHeight(), $(window).width())

    const chairKingSmallRefList = [useRef()]
    const chairKingSmallDraggableList = chairKingSmallRefList.map((chairKingSmallRef, index) => {
        totalElementList.push(chairKingSmallRef)
        const chairKingSmallImg =
            <ChairKingSmallImg
                draggable={false}
                ref={chairKingSmallRef}
                src={PathChairKingSmall}/>
            handleElementZIndex(chairKingSmallRef)
        return (
            <Draggable
                key={index}
                bounds={ chairKingSmallParams.bounds }
                defaultPosition={ chairKingSmallParams.pos }>
                { chairKingSmallImg }
            </Draggable>
        )
    })

    const updateChairKingSmallCount = (newValue) => {
        updateElementCount(newValue, chairKingSmallRefList)
    }



    const podiumParams = getElementParams(getPodiumHeight(), $(window).width())

    const podiumCircleRefList = [useRef(), useRef()]
    const podiumCircleDraggableList = podiumCircleRefList.map((podiumCircleRef, index) => {
        totalElementList.push(podiumCircleRef)
        const podiumCircleImg =
            <PodiumImg
                draggable={false}
                ref={podiumCircleRef}
                src={PathPodiumCircle}/>
            handleElementZIndex(podiumCircleRef)
        return (
            <Draggable
                key={index}
                bounds={ podiumParams.bounds }
                defaultPosition={ podiumParams.pos }>
                { podiumCircleImg }
            </Draggable>
        )
    })

    const updatePodiumCircleCount = (newValue) => {
        updateElementCount(newValue, podiumCircleRefList)
    }


    

    const pedestalParams = getElementParams(getPedestalHeight(), $(window).width())
    const pedestalCylinderRefList = [useRef(), useRef(), useRef()]
    const pedestalCylinderDraggableList = pedestalCylinderRefList.map((pedestalCylinderRef, index) => {
        totalElementList.push(pedestalCylinderRef)
        const pedestalCylinderImg =
            <PedestalImg
                draggable={false}
                ref={pedestalCylinderRef}
                src={PathPedestalCylinder}/>
            handleElementZIndex(pedestalCylinderRef)
        return (
            <Draggable
                key={index}
                bounds={ pedestalParams.bounds }
                defaultPosition={ pedestalParams.pos }>
                { pedestalCylinderImg }
            </Draggable>
        )
    })
    const updatePedestalCylinderCount = (newValue) => {
        updateElementCount(newValue, pedestalCylinderRefList)
    }

    const pedestalSquareRefList = [useRef(), useRef(), useRef()]
    const pedestalSquareDraggableList = pedestalSquareRefList.map((pedestalSquareRef, index) => {
        totalElementList.push(pedestalSquareRef)
        const pedestalSquareImg =
            <PedestalImg
                draggable={false}
                ref={pedestalSquareRef}
                src={PathPedestalSquare}/>
            handleElementZIndex(pedestalSquareRef)
        return (
            <Draggable
                key={index}
                bounds={ pedestalParams.bounds }
                defaultPosition={ pedestalParams.pos }>
                { pedestalSquareImg }
            </Draggable>
        )
    })
    const updatePedestalSquareCount = (newValue) => {
        updateElementCount(newValue, pedestalSquareRefList)
    }


    const pedestalSquareTransRefList = [useRef(), useRef(), useRef(), useRef()]
    const pedestalSquareTransDraggableList = pedestalSquareTransRefList.map((pedestalSquareTransRef, index) => {
        totalElementList.push(pedestalSquareTransRef)
        const pedestalSquareTransImg =
            <PedestalImg
                draggable={false}
                ref={pedestalSquareTransRef}
                src={PathPedestalSquareTrans}/>
            handleElementZIndex(pedestalSquareTransRef)
        return (
            <Draggable
                key={index}
                bounds={ pedestalParams.bounds }
                defaultPosition={ pedestalParams.pos }>
                { pedestalSquareTransImg }
            </Draggable>
        )
    })
    const updatePedestalSquareTransCount = (newValue) => {
        updateElementCount(newValue, pedestalSquareTransRefList)
    }


    const pedestalLongParams = getElementParams(getPedestalLongHeight(), $(window).width())
    const pedestalPilarRefList = [useRef(), useRef()]
    const pedestalPilarDraggableList = pedestalPilarRefList.map((pedestalPilarRef, index) => {
        totalElementList.push(pedestalPilarRef)
        const pedestalPilarImg =
            <PedestalLongImg
                draggable={false}
                ref={pedestalPilarRef}
                src={PathPedestalPilar}/>
            handleElementZIndex(pedestalPilarRef)
        return (
            <Draggable
                key={index}
                bounds={ pedestalLongParams.bounds }
                defaultPosition={ pedestalLongParams.pos }>
                { pedestalPilarImg }
            </Draggable>
        )
    })
    const updatePedestalPilarCount = (newValue) => {
        updateElementCount(newValue, pedestalPilarRefList)
    }



    const tableParams = getElementParams(getTableHeight(), $(window).width())
    const tableIronSquareRefList = [useRef(), useRef(), useRef(), useRef()]
    const tableIronSquareDraggableList = tableIronSquareRefList.map((tableIronSquareRef, index) => {
        totalElementList.push(tableIronSquareRef)
        const tableIronSquareImg =
            <TableImg
                draggable={false}
                ref={tableIronSquareRef}
                src={PathTableIronSquare}/>
            handleElementZIndex(tableIronSquareRef)
        return (
            <Draggable
                key={index}
                bounds={ tableParams.bounds }
                defaultPosition={ tableParams.pos }>
                { tableIronSquareImg }
            </Draggable>
        )
    })
    const updateTableIronSquareCount = (newValue) => {
        updateElementCount(newValue, tableIronSquareRefList)
    }



    const tableIronHourglassRefList = [useRef(), useRef(), useRef(), useRef()]
    const tableIronHourglassDraggableList = tableIronHourglassRefList.map((tableIronHourglassRef, index) => {
        totalElementList.push(tableIronHourglassRef)
        const tableIronHourglassImg =
            <TableImg
                draggable={false}
                ref={tableIronHourglassRef}
                src={PathTableIronHourglass}/>
            handleElementZIndex(tableIronHourglassRef)
        return (
            <Draggable
                key={index}
                bounds={ tableParams.bounds }
                defaultPosition={ tableParams.pos }>
                { tableIronHourglassImg }
            </Draggable>
        )
    })
    const updateTableIronHourglassCount = (newValue) => {
        updateElementCount(newValue, tableIronHourglassRefList)
    }



    const numberParams = getElementParams(getNumberHeight(), $(window).width())
    const numberZeroRef = useRef()
    totalElementList.push(numberZeroRef)
    const numberZeroImg = 
        <NumberZeroImg
            draggable={false}
            ref={numberZeroRef}
            src={PathNumberZero}/>
        handleElementZIndex(numberZeroRef)

    const numberZeroRefList = [numberZeroRef]

    const updateNumberZeroCount = (newValue) => {
        updateElementCount(newValue, numberZeroRefList)
    }

    const numberOneRef = useRef()
    totalElementList.push(numberOneRef)
    const numberOneImg = 
        <NumberOneImg
            draggable={false}
            ref={numberOneRef}
            src={PathNumberOne}/>
        handleElementZIndex(numberOneRef)

    const numberOneRefList = [numberOneRef]

    const updateNumberOneCount = (newValue) => {
        updateElementCount(newValue, numberOneRefList)
    }

    const numberThreeRef = useRef()
    totalElementList.push(numberThreeRef)
    const numberThreeImg = 
        <NumberThreeImg
            draggable={false}
            ref={numberThreeRef}
            src={PathNumberThree}/>
        handleElementZIndex(numberThreeRef)

    const numberThreeRefList = [numberThreeRef]

    const updateNumberThreeCount = (newValue) => {
        updateElementCount(newValue, numberThreeRefList)
    }

    const numberFiveRef = useRef()
    totalElementList.push(numberFiveRef)
    const numberFiveImg = 
        <NumberFiveImg
            draggable={false}
            ref={numberFiveRef}
            src={PathNumberFive}/>
        handleElementZIndex(numberFiveRef)

    const numberFiveRefList = [numberFiveRef]

    const updateNumberFiveCount = (newValue) => {
        updateElementCount(newValue, numberFiveRefList)
    }

    


    const clearAllElements = () => {
        updateShimmerSilverCount(0)
        counterShimmerSilverRef.current.cleanCounter()
        updateShimmerGoldenCount(0)
        counterShimmerGoldenRef.current.cleanCounter()
        updateShimmerColorsCount(0)
        counterShimmerColorsRef.current.cleanCounter()
        updateBackgroundArchPinkCount(0)
        counterBackgroundArchPinkRef.current.cleanCounter()
        updateBackgroundArchGreenCount(0)
        counterBackgroundArchGreenRef.current.cleanCounter()
        updateBackgroundArchSkyCount(0)
        counterBackgroundArchSkyRef.current.cleanCounter()
        updateBackgroundSquarePinkCount(0)
        counterBackgroundSquarePinkRef.current.cleanCounter()
        updateBackgroundSquareGreenCount(0)
        counterBackgroundSquareGreenRef.current.cleanCounter()
        updateBackgroundSquareSkyCount(0)
        counterBackgroundSquareSkyRef.current.cleanCounter()
        updateBackgroundCircleImageCount(0)
        counterBackgroundCircleImageRef.current.cleanCounter()
        updateBackgroundCircleCurtainCount(0)
        counterBackgroundCircleCurtainRef.current.cleanCounter()
        updateBalloonGoldenCount(0)
        counterBalloonGoldenRef.current.cleanCounter()
        updateBalloonRedCount(0)
        counterBalloonRedRef.current.cleanCounter()
        updateBalloonWhiteCount(0)
        counterBalloonWhiteRef.current.cleanCounter()
        updateBalloonTransparentCount(0)
        counterBalloonTransparentRef.current.cleanCounter()
        updateBalloonsGroup4GoldenCount(0)
        counterBalloonsGroup4GoldenRef.current.cleanCounter()
        updateBalloonsGroup4RedCount(0)
        counterBalloonsGroup4RedRef.current.cleanCounter()
        updateBalloonsGroup4WhiteCount(0)
        counterBalloonsGroup4WhiteRef.current.cleanCounter()
        updateArchStdCount(0)
        counterArchStdRef.current.cleanCounter()
        updateArchCorneredCount(0)
        counterArchCorneredRef.current.cleanCounter()
        updateArchInfiniteCount(0)
        counterArchInfiniteRef.current.cleanCounter()
        updatePedestalCylinderCount(0)
        counterPedestalCylinderRef.current.cleanCounter()
        updatePedestalSquareCount(0)
        counterPedestalSquareRef.current.cleanCounter()
        updatePedestalSquareTransCount(0)
        counterPedestalSquareTransRef.current.cleanCounter()
        updatePedestalPilarCount(0)
        counterPedestalPilarRef.current.cleanCounter()
        updateTableIronSquareCount(0)
        counterTableIronSquareRef.current.cleanCounter()
        updateTableIronHourglassCount(0)
        counterTableIronHourglassRef.current.cleanCounter()
        updatePodiumCircleCount(0)
        counterPodiumCircleRef.current.cleanCounter()
        updateChairKingCount(0)
        counterChairKingRef.current.cleanCounter()
        updateChairKingSmallCount(0)
        counterChairKingSmallRef.current.cleanCounter()
        updateNumberZeroCount(0)
        counterNumberZeroRef.current.cleanCounter()
        updateNumberOneCount(0)
        counterNumberOneRef.current.cleanCounter()
        updateNumberThreeCount(0)
        counterNumberThreeRef.current.cleanCounter()
        updateNumberFiveCount(0)
        counterNumberFiveRef.current.cleanCounter()
    }



    const collapsingShimmerRef = useRef()
    const collapsingBackgroundRef = useRef()
    const collapsingBalloonRef = useRef()
    const collapsingArchRef = useRef()
    const collapsingPedestalRef = useRef()
    const collapsingTableRef = useRef()
    const collapsingPodiumRef = useRef()
    const collapsingChairRef = useRef()
    const collapsingNumberRef = useRef()

    const counterShimmerSilverRef = useRef()
    const counterShimmerGoldenRef = useRef()
    const counterShimmerColorsRef = useRef()
    const counterBackgroundArchPinkRef = useRef()
    const counterBackgroundArchSkyRef = useRef()
    const counterBackgroundArchGreenRef = useRef()
    const counterBackgroundSquarePinkRef = useRef()
    const counterBackgroundSquareSkyRef = useRef()
    const counterBackgroundSquareGreenRef = useRef()
    const counterBackgroundCircleImageRef = useRef()
    const counterBackgroundCircleCurtainRef = useRef()
    const counterBalloonGoldenRef = useRef()
    const counterBalloonRedRef = useRef()
    const counterBalloonWhiteRef = useRef()
    const counterBalloonTransparentRef = useRef()
    const counterBalloonsGroup4GoldenRef = useRef()
    const counterBalloonsGroup4RedRef = useRef()
    const counterBalloonsGroup4WhiteRef = useRef()
    const counterArchStdRef = useRef()
    const counterArchCorneredRef = useRef()
    const counterArchInfiniteRef = useRef()
    const counterPedestalCylinderRef = useRef()
    const counterPedestalSquareRef = useRef()
    const counterPedestalSquareTransRef = useRef()
    const counterPedestalPilarRef = useRef()
    const counterTableIronSquareRef = useRef()
    const counterTableIronHourglassRef = useRef()
    const counterPodiumCircleRef = useRef()
    const counterChairKingRef = useRef()
    const counterChairKingSmallRef = useRef()
    const counterNumberZeroRef = useRef()
    const counterNumberOneRef = useRef()
    const counterNumberThreeRef = useRef()
    const counterNumberFiveRef = useRef()
    



    const [editModal, setEditModal] = useState();
    const [editImageModal, setEditImageModal] = useState();

    useEffect(() => {
        $(window).on('load', function() {
            var editModal = M.Modal.getInstance($(`#${EDIT_MODAL.id}`)); // Init modal
            setEditModal(editModal);

            var editImageModal = M.Modal.getInstance($(`#${EDIT_IMAGE_MODAL.id}`)); // Init image modal
            setEditImageModal(editImageModal);

            isWindowFirstLoad = true
        });

        var modalContent = $(`#${EDIT_MODAL.id}`).find('.modal-content')
        modalContent.css("background-color", COLOR.background);

        var modalFooter = $(`#${EDIT_MODAL.id}`).find('.modal-footer')
        modalFooter.css("background-color", COLOR.background);

        var imageModalContent = $(`#${EDIT_IMAGE_MODAL.id}`).find('.modal-content')
        imageModalContent.css("background-color", COLOR.background);

        var imageModalFooter = $(`#${EDIT_IMAGE_MODAL.id}`).find('.modal-footer')
        imageModalFooter.css("background-color", COLOR.background);
    }, [])

    return (
        <div>

            <Banner title={LABEL.sketch}/>

            <Row margin='0 0'>
                <Path 
                    endpoint={LABEL.sketch}
                    margin='35px 20px' />
            </Row>

            <Row margin='10px 0 10px 0'>
            
            </Row>

            <SketchBackground>

                { shimmerSilverDraggableList }

                { shimmerGoldenDraggableList }

                { shimmerColorsDraggableList }


                { backgroundArchPinkDraggableList }

                { backgroundArchSkyDraggableList }

                { backgroundArchGreenDraggableList }


                { backgroundSquarePinkDraggableList }

                { backgroundSquareSkyDraggableList }

                { backgroundSquareGreenDraggableList }


                { backgrounCircleImagDraggabble }

                <Draggable
                    bounds={ backgroundCircleParams.bounds }
                    defaultPosition={ backgroundCircleParams.pos }>
                    { backgroundCircleCurtainImg }
                </Draggable>
                


                { balloonsGroup4RedDraggableList }

                { balloonRedDraggableList }

                { balloonsGroup4GoldenDraggableList }

                { balloonGoldenDraggableList }

                { balloonsGroup4WhiteDraggableList }

                { balloonWhiteDraggableList }

                { balloonTransparentDraggableList }



                { archStfDraggableList }

                { archCorneredDraggableList }

                { archInfiniteDraggableList }



                { chairKingDraggableList }

                { chairKingSmallDraggableList }



                { podiumCircleDraggableList }



                { pedestalCylinderDraggableList }

                { pedestalSquareDraggableList }

                { pedestalSquareTransDraggableList }

                { pedestalPilarDraggableList }



                { tableIronSquareDraggableList }

                { tableIronHourglassDraggableList }



                <Draggable
                    bounds={ numberParams.bounds }
                    defaultPosition={ numberParams.pos }>
                    { numberZeroImg }
                </Draggable>

                <Draggable
                    bounds={ numberParams.bounds }
                    defaultPosition={ numberParams.pos }>
                    { numberOneImg }
                </Draggable>

                <Draggable
                    bounds={ numberParams.bounds }
                    defaultPosition={ numberParams.pos }>
                    { numberThreeImg }
                </Draggable>

                <Draggable
                    bounds={ numberParams.bounds }
                    defaultPosition={ numberParams.pos }>
                    { numberFiveImg }
                </Draggable>


                


                <TextButton
                    ic_path={PathCircledEditIc}
                    ic_size='40px'
                    onClick={ () => { editModal.open() } }
                    margin='10px 0'
                    float='right' />

            </SketchBackground>

            <ToastContainer
                autoClose={ 1500 }/>

            <ModalStyled
                id={ EDIT_MODAL.id }
                fixedFooter={ true }
                options={{
                    onOpenEnd: () => {
                        if (isWindowFirstLoad) {
                            collapsingShimmerRef.current.refresh()
                            collapsingBackgroundRef.current.refresh()
                            collapsingBalloonRef.current.refresh()
                            collapsingArchRef.current.refresh()
                            collapsingPedestalRef.current.refresh()
                            collapsingTableRef.current.refresh()
                            collapsingPodiumRef.current.refresh()
                            collapsingChairRef.current.refresh()
                            collapsingNumberRef.current.refresh()
                            isWindowFirstLoad = false
                        }
                    }
                }}
                actions={[
                    <Button 
                        onClick={() => { editModal.close() }}
                        width='90px'
                        height='36px'
                        bg_color={COLOR.primary}
                        float='right'>
                        <Label
                            text_color={ COLOR.ferra }
                            text_size={ SIZE.subtitle }
                            font_family={ FONT_FAMILY.antipastoPro }
                            margin='auto'>
                            { LABEL.close }
                        </Label>
                    </Button>,
                    <Button 
                        onClick={() => { clearAllElements() }}
                        width='120px'
                        height='36px'
                        bg_color={COLOR.primary}
                        float='right'>
                        <Label
                            text_color={ COLOR.ferra }
                            text_size={ SIZE.body }
                            font_family={ FONT_FAMILY.antipastoPro }
                            margin='auto'>
                            { LABEL.clear_all }
                        </Label>
                    </Button>
                ]}>
            <div>
                <Collapsible
                    ref={ collapsingShimmerRef }
                    title = { LABEL.sketch_shimmer }
                    title_id='shimmerTitleId'
                    icon_id='shimmerIconId'>
                    <LabelCounterButtons
                        ref={ counterShimmerSilverRef }
                        labelTitle={ LABEL.sketch_shimmer_silver }
                        minValue={0}
                        maxValue={ shimmerSilverRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateShimmerSilverCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterShimmerGoldenRef }
                        labelTitle={ LABEL.sketch_shimmer_golden }
                        minValue={0}
                        maxValue={ shimmerGoldenRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateShimmerGoldenCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterShimmerColorsRef }
                        labelTitle={ LABEL.sketch_shimmer_colors }
                        minValue={0}
                        maxValue={ shimmerColorsRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateShimmerColorsCount(newValue) }/>
                </Collapsible>

                <Collapsible
                    ref={ collapsingBackgroundRef }
                    title = { LABEL.sketch_background }
                    title_id='backgroundTitleId'
                    icon_id='backgroundIconId'>
                    <LabelCounterButtons
                        ref={ counterBackgroundArchPinkRef }
                        labelTitle={ LABEL.sketch_background_arch_pink }
                        minValue={0}
                        maxValue={ backgroundArchPinkRefList.length }
                        defaultValue={1}
                        onCounterChanged={ (newValue) => updateBackgroundArchPinkCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBackgroundArchSkyRef }
                        labelTitle={ LABEL.sketch_background_arch_sky }
                        minValue={0}
                        maxValue={ backgroundArchSkyRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBackgroundArchSkyCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBackgroundArchGreenRef }
                        labelTitle={ LABEL.sketch_background_arch_green }
                        minValue={0}
                        maxValue={ backgroundArchGreenRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBackgroundArchGreenCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBackgroundSquarePinkRef }
                        labelTitle={ LABEL.sketch_background_square_pink }
                        minValue={0}
                        maxValue={ backgroundSquarePinkRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBackgroundSquarePinkCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBackgroundSquareSkyRef }
                        labelTitle={ LABEL.sketch_background_square_sky }
                        minValue={0}
                        maxValue={ backgroundSquareSkyRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBackgroundSquareSkyCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBackgroundSquareGreenRef }
                        labelTitle={ LABEL.sketch_background_square_green }
                        minValue={0}
                        maxValue={ backgroundSquareGreenRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBackgroundSquareGreenCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBackgroundCircleImageRef }
                        labelTitle={ LABEL.sketch_background_circle_image }
                        minValue={0}
                        maxValue={ backgroundCircleImageContentRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBackgroundCircleImageCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBackgroundCircleCurtainRef }
                        labelTitle={ LABEL.sketch_background_circle_curtain }
                        minValue={0}
                        maxValue={ backgroundCircleCurtainRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBackgroundCircleCurtainCount(newValue) }/>
                </Collapsible>


                <Collapsible
                    ref={ collapsingBalloonRef }
                    title = { LABEL.sketch_balloon }
                    title_id='balloonTitleId'
                    icon_id='balloonIconId'>
                    <LabelCounterButtons
                        ref={ counterBalloonGoldenRef }
                        labelTitle={ LABEL.sketch_balloon_golden }
                        minValue={0}
                        maxValue={ balloonGoldenRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBalloonGoldenCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBalloonRedRef }
                        labelTitle={ LABEL.sketch_balloon_red }
                        minValue={0}
                        maxValue={ balloonRedRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBalloonRedCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBalloonWhiteRef }
                        labelTitle={ LABEL.sketch_balloon_white }
                        minValue={0}
                        maxValue={ balloonWhiteRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBalloonWhiteCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBalloonTransparentRef }
                        labelTitle={ LABEL.sketch_balloon_transparent }
                        minValue={0}
                        maxValue={ balloonTransparentRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBalloonTransparentCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBalloonsGroup4GoldenRef }
                        labelTitle={ LABEL.sketch_balloons_group4_golden }
                        minValue={0}
                        maxValue={ balloonsGroup4GoldenRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBalloonsGroup4GoldenCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBalloonsGroup4RedRef }
                        labelTitle={ LABEL.sketch_balloons_group4_red }
                        minValue={0}
                        maxValue={ balloonsGroup4RedRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBalloonsGroup4RedCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterBalloonsGroup4WhiteRef }
                        labelTitle={ LABEL.sketch_balloons_group4_white }
                        minValue={0}
                        maxValue={ balloonsGroup4WhiteRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateBalloonsGroup4WhiteCount(newValue) }/>
                </Collapsible>

                <Collapsible
                    ref={ collapsingArchRef }
                    title = { LABEL.sketch_arch }
                    title_id='archTitleId'
                    icon_id='archIconId'>
                    <LabelCounterButtons
                        ref={ counterArchStdRef }
                        labelTitle={ LABEL.sketch_arch_std }
                        minValue={0}
                        maxValue={ archStdRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateArchStdCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterArchCorneredRef }
                        labelTitle={ LABEL.sketch_arch_cornered }
                        minValue={0}
                        maxValue={ archCorneredRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateArchCorneredCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterArchInfiniteRef }
                        labelTitle={ LABEL.sketch_arch_infinite }
                        minValue={0}
                        maxValue={ archInfiniteRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateArchInfiniteCount(newValue) }/>
                </Collapsible>

                <Collapsible
                    ref={ collapsingChairRef }
                    title = { LABEL.sketch_chairs }
                    title_id='chairTitleId'
                    icon_id='chairIconId'>
                    <LabelCounterButtons
                        ref={ counterChairKingRef }
                        labelTitle={ LABEL.sketch_chair_king }
                        minValue={0}
                        maxValue={ chairKingRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateChairKingCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterChairKingSmallRef }
                        labelTitle={ LABEL.sketch_chair_king_small }
                        minValue={0}
                        maxValue={ chairKingSmallRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateChairKingSmallCount(newValue) }/>
                </Collapsible>

                <Collapsible
                    ref={ collapsingPodiumRef }
                    title = { LABEL.sketch_podiums }
                    title_id='podiumTitleId'
                    icon_id='podiumIconId'>
                    <LabelCounterButtons
                        ref={ counterPodiumCircleRef }
                        labelTitle={ LABEL.sketch_podium_circle }
                        minValue={0}
                        maxValue={ podiumCircleRefList.length }
                        defaultValue={1}
                        onCounterChanged={ (newValue) => updatePodiumCircleCount(newValue) }/>
                </Collapsible>

                <Collapsible
                    ref={ collapsingPedestalRef }
                    title = { LABEL.sketch_pedestal }
                    title_id='pedestalTitleId'
                    icon_id='pedestalIconId'>
                    <LabelCounterButtons
                        ref={ counterPedestalCylinderRef }
                        labelTitle={ LABEL.sketch_pedestal_cylinder }
                        minValue={0}
                        maxValue={ pedestalCylinderRefList.length }
                        defaultValue={1}
                        onCounterChanged={ (newValue) => updatePedestalCylinderCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterPedestalSquareRef }
                        labelTitle={ LABEL.sketch_pedestal_square }
                        minValue={0}
                        maxValue={ pedestalSquareRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updatePedestalSquareCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterPedestalSquareTransRef }
                        labelTitle={ LABEL.sketch_pedestal_square_trans }
                        minValue={0}
                        maxValue={ pedestalSquareTransRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updatePedestalSquareTransCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterPedestalPilarRef }
                        labelTitle={ LABEL.sketch_pedestal_pilar }
                        minValue={0}
                        maxValue={ pedestalPilarRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updatePedestalPilarCount(newValue) }/>
                </Collapsible>

                <Collapsible
                    ref={ collapsingTableRef }
                    title = { LABEL.sketch_table }
                    title_id='tableTitleId'
                    icon_id='tableIconId'>
                    <LabelCounterButtons
                        ref={ counterTableIronSquareRef }
                        labelTitle={ LABEL.sketch_table_iron_square }
                        minValue={0}
                        maxValue={ tableIronSquareRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateTableIronSquareCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterTableIronHourglassRef }
                        labelTitle={ LABEL.sketch_table_iron_hourglass }
                        minValue={0}
                        maxValue={ tableIronHourglassRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateTableIronHourglassCount(newValue) }/>
                </Collapsible>

                <Collapsible
                    ref={ collapsingNumberRef }
                    title = { LABEL.sketch_number }
                    title_id='numberTitleId'
                    icon_id='numberIconId'>
                    <LabelCounterButtons
                        ref={ counterNumberZeroRef }
                        labelTitle={ LABEL.sketch_number_zero }
                        minValue={0}
                        maxValue={ numberZeroRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateNumberZeroCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterNumberOneRef }
                        labelTitle={ LABEL.sketch_number_one }
                        minValue={0}
                        maxValue={ numberOneRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateNumberOneCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterNumberThreeRef }
                        labelTitle={ LABEL.sketch_number_three }
                        minValue={0}
                        maxValue={ numberThreeRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateNumberThreeCount(newValue) }/>
                    <LabelCounterButtons
                        ref={ counterNumberFiveRef }
                        labelTitle={ LABEL.sketch_number_five }
                        minValue={0}
                        maxValue={ numberFiveRefList.length }
                        defaultValue={0}
                        onCounterChanged={ (newValue) => updateNumberFiveCount(newValue) }/>
                    
                </Collapsible>
            </div>
            </ModalStyled>


            <ModalStyled
                id={ EDIT_IMAGE_MODAL.id }
                fixedFooter={ true }
                actions={[
                    <Button 
                        onClick={() => { editImageModal.close() }}
                        width='90px'
                        height='36px'
                        bg_color={COLOR.primary}
                        float='right'>
                        <Label
                            text_color={ COLOR.ferra }
                            text_size={ SIZE.subtitle }
                            font_family={ FONT_FAMILY.antipastoPro }
                            margin='auto'>
                            { LABEL.close }
                        </Label>
                    </Button>
                ]}>
                <div>
                    <Row className='valign-wrapper'>
                        <Col offset='s3'>
                        { imagePickerPreviewImg }
                        </Col>
                    </Row>
                </div>
            </ModalStyled>

        </div>
    );
}

export default BodySketch;