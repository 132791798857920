import React, { useRef } from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-materialize';
import Row from '../organism/row.jsx';
import { COLOR, SIZE, FONT_FAMILY } from '../../utils/constants.js';
import { Label } from '../atoms/label.jsx';
import CounterButtons from './counterbuttons.jsx';

const LabelCounterButtons = forwardRef((props, ref) => {

    const counterButtonsRef = useRef()

    useImperativeHandle(ref, () => ({
		cleanCounter: () => { handleCleanCounter() }
	}));

    const handleCleanCounter = () => {
        counterButtonsRef.current.cleanCounter()
    }

    return (
        <Row margin='10px 0px' className='valign-wrapper'>
            <Col>
                <Label
                    text_size={ SIZE.subtitle }
                    text_color={ COLOR.ferra }
                    font_family={ FONT_FAMILY.antipastoPro }>
                    { props.labelTitle }
                </Label>
            </Col>
            <CounterButtons
                ref={counterButtonsRef}
                minValue={ props.minValue }
                maxValue={ props.maxValue }
                defaultValue={ props.defaultValue }
                textColor={ COLOR.ferra }
                onCounterChanged={ (newValue) => props.onCounterChanged(newValue) }/>
        </Row>
    )
})

LabelCounterButtons.propTypes = {
    labelTitle: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    defaultValue: PropTypes.number,
    onCounterChanged: PropTypes.func
}

export default LabelCounterButtons;