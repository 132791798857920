import React from 'react';
import PropTypes, { string, object } from 'prop-types';
import styled from 'styled-components';
import { COLOR, SIZE, SCREEN_MEDIA } from '../../utils/constants.js';
import Img from '../atoms/img.jsx';

const Container = styled.div`
     position: relative;
     margin: ${props => props.margin ? props.margin : 'none'};
`;

const Picture01 = styled(Img)`
     display: block;
     @media only screen and ${SCREEN_MEDIA.small} {
          border: 12px solid ${COLOR.background};
     }
     @media only screen and ${SCREEN_MEDIA.medium} {
          border: 15px solid ${COLOR.background};
     }
     @media only screen and ${SCREEN_MEDIA.long} {
          border: 18px solid ${COLOR.background};
     }
     @media only screen and ${SCREEN_MEDIA.xlong} {
          border: 20px solid ${COLOR.background};
     }
`

const Picture02 = styled(Img)`
     display: block;
     position: absolute;
     top: 0px;
     @media only screen and ${SCREEN_MEDIA.small} {
          border: 12px solid ${COLOR.background};
          margin: 0 0 0 90px;
     }
     @media only screen and ${SCREEN_MEDIA.medium} {
          border: 15px solid ${COLOR.background};
          margin: 0 0 0 120px;
     }
     @media only screen and ${SCREEN_MEDIA.long} {
          border: 18px solid ${COLOR.background};
          margin: 0 0 0 150px;
     }
     @media only screen and ${SCREEN_MEDIA.xlong} {
          border: 20px solid ${COLOR.background};
          margin: 0 0 0 180px;
     }
`

const Picture03 = styled(Img)`
     display: block;
     position: absolute;
     top: 0px;
     @media only screen and ${SCREEN_MEDIA.small} {
          border: 12px solid ${COLOR.background};
          margin: 0 0 0 210px;
     }
     @media only screen and ${SCREEN_MEDIA.medium} {
          border: 15px solid ${COLOR.background};
          margin: 0 0 0 240px;
     }
     @media only screen and ${SCREEN_MEDIA.long} {
          border: 18px solid ${COLOR.background};
          margin: 0 0 0 300px;
     }
     @media only screen and ${SCREEN_MEDIA.xlong} {
          border: 20px solid ${COLOR.background};
          margin: 0 0 0 360px;
     }
`


const Photos = (props) => {
     return (
          <Container
               className='valign-wrapper'
               margin={props.margin}>
               <Picture01
                    src={props.photo_01_src}
                    transform={`rotate(${props.photo_01_rotation})`}
                    shadow='z-depth-3'/>
               <Picture02
                    src={props.photo_02_src}
                    transform={`rotate(${props.photo_02_rotation})`}
                    shadow='z-depth-3'/>
               <Picture03
                    src={props.photo_03_src}
                    transform={`rotate(${props.photo_03_rotation})`}
                    shadow='z-depth-3'/>
          </Container>
     )
}

Photos.propTypes = {
     photo_01_src: PropTypes.string,
     photo_01_rotation: PropTypes.string,
     photo_02_src: PropTypes.string,
     photo_02_rotation: PropTypes.string,
     photo_03_src: PropTypes.string,
     photo_03_rotation: PropTypes.string,
     margin: PropTypes.string,
}

export default Photos;