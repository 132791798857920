import { CATEGORY, THEMATIC } from './constants.js';

import PathImgBabyshower_001 from '../images_categories/babyshower/img_001.jpg';
import PathImgBabyshower_002 from '../images_categories/babyshower/img_002.jpg';
import PathImgBabyshower_003 from '../images_categories/babyshower/img_003.jpg';
import PathImgBabyshower_004_001 from '../images_categories/babyshower/img_004_01.jpg';
import PathImgBabyshower_004_002 from '../images_categories/babyshower/img_004_02.jpg';
import PathImgBabyshower_005_001 from '../images_categories/babyshower/img_005_001.jpg';
import PathImgBabyshower_005_002 from '../images_categories/babyshower/img_005_002.jpg';
import PathImgBabyshower_005_003 from '../images_categories/babyshower/img_005_003.jpg';
import PathImgBabyshower_005_004 from '../images_categories/babyshower/img_005_004.jpg';
import PathImgBabyshower_005_005 from '../images_categories/babyshower/img_005_005.jpg';
import PathImgBabyshower_005_006 from '../images_categories/babyshower/img_005_006.jpg';
import PathImgBabyshower_006_001 from '../images_categories/babyshower/img_006_001.jpg';
import PathImgBabyshower_006_002 from '../images_categories/babyshower/img_006_002.jpg';
import PathImgBabyshower_006_003 from '../images_categories/babyshower/img_006_003.jpg';
import PathImgBabyshower_006_004 from '../images_categories/babyshower/img_006_004.jpg';
import PathImgBabyshower_006_005 from '../images_categories/babyshower/img_006_005.jpg';
import PathImgBabyshower_007_002 from '../images_categories/babyshower/img_007_002.jpg';
import PathImgBabyshower_007_003 from '../images_categories/babyshower/img_007_003.jpg';
import PathImgBabyshower_007_004 from '../images_categories/babyshower/img_007_004.jpg';
import PathImgBabyshower_007_005 from '../images_categories/babyshower/img_007_005.jpg';
import PathImgBabyshower_008_001 from '../images_categories/babyshower/img_008_001.jpg';
import PathImgBabyshower_008_002 from '../images_categories/babyshower/img_008_002.jpg';
import PathImgBabyshower_008_007 from '../images_categories/babyshower/img_008_007.jpg';
import PathImgBabyshower_008_008 from '../images_categories/babyshower/img_008_008.jpg';


const PICTURES_BABYSHOWER = [
    {
        src: PathImgBabyshower_005_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_005_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_005_003,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_005_004,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_005_005,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_005_006,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_008_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.looneyTunes,
        title: THEMATIC.looneyTunes.title
    },
    {
        src: PathImgBabyshower_008_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.looneyTunes,
        title: THEMATIC.looneyTunes.title
    },
    {
        src: PathImgBabyshower_008_007,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.looneyTunes,
        title: THEMATIC.looneyTunes.title
    },
    {
        src: PathImgBabyshower_008_008,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.looneyTunes,
        title: THEMATIC.looneyTunes.title
    },
    {
        src: PathImgBabyshower_004_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_004_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_006_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_006_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_006_003,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_006_004,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_006_005,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.safari,
        title: THEMATIC.safari.title
    },
    {
        src: PathImgBabyshower_007_005,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_007_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_007_003,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_007_004,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_003,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    },
    {
        src: PathImgBabyshower_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title
    }
]

export { PICTURES_BABYSHOWER }