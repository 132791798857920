import React, { useState, useEffect, useRef } from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import styled from 'styled-components';
import { COLOR, SIZE, FONT_FAMILY } from '../../utils/constants.js';
import TextButton from '../atoms/textbutton.jsx';
import { Label } from '../atoms/label.jsx';

import PathCircledAddIc from '../../icons/ic_circled_add.svg'
import PathCircledLessIc from '../../icons/ic_circled_less.svg'

const CounterButtonsContent = styled.div`
    display: flex;
    padding: ${props => props.padding ? props.padding : 'none'};
    float: ${props => props.float ? props.float : 'left'};
    margin: ${props => props.margin ? props.margin : '0 0'};
`;

const CounterLabelContent = styled.div`
    width: 10px;
`;

const CounterButtons = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
		cleanCounter: () => { updateCounter(0) }
	}));

    const [counter, setCounter] = useState(props.defaultValue)
    const updateCounter = (newValue) => {
        if (newValue >= props.minValue && newValue <= props.maxValue) {
            setCounter(newValue)
            props.onCounterChanged(newValue)
        }
    }

    useEffect(() => {
        $(window).on('load', function() {
            updateCounter(counter)
        });
    }, [])

    return (
        <CounterButtonsContent>
            <TextButton
                target="_blank"
                ic_path={PathCircledLessIc}
                ic_size='32px'
                onClick={ () => updateCounter(counter - 1) }/>
            <CounterLabelContent>
                <Label
                    text_size={ SIZE.subtitle }
                    text_color={ props.textColor }
                    font_family={FONT_FAMILY.noteworthyLight}
                    text_align='right'>
                    {counter}
                </Label>
            </CounterLabelContent>
            <TextButton
                target="_blank"
                ic_path={PathCircledAddIc}
                ic_size='32px'
                onClick={ () => updateCounter(counter + 1) }/>
        </CounterButtonsContent>
    )
})

CounterButtons.propTypes = {
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    defaultValue: PropTypes.number,
    textColor: PropTypes.string,
    onCounterChanged: PropTypes.func
}

export default CounterButtons;