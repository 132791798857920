import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLOR, FONT_FAMILY, SCREEN_MEDIA } from '../../utils/constants.js';
import { Label } from '../atoms/label.jsx';


const SlideStyled = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    background-image: url(${props => props.background});
    // Cover and center background image: //
    background-position: 50% 50%; /*El cuadro ajustado se puede centrar horizontal y verticalmente*/
    background-size: cover; /*El cuadro de la imagen se ajusta a la dimensión más pequeña del div con el objetivo de cubrirla*/
    background-repeat: no-repeat;
    // To make the overlay: //
    position: absolute;
    z-index: 0;
    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: ${COLOR.black};
        opacity: ${props => props.layer_opacity ? props.layer_opacity : 0.6};
    }
`;

const Title = styled(Label)`
    width: 78%;
    font-size: 60px;
    top: 15%;
	left: 7%;
    @media only screen and ${SCREEN_MEDIA.small} {
        width: 78%;
        font-size: 25px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        width: 70%;
        font-size: 30px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        width: 65%;
        font-size: 35px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        width: 60%;
        font-size: 55px;
    }
`;

const Description = styled(Label)`
    width: 60%;
    font-size: 35px;
    top: 45%;
    right: 10%;
    @media only screen and ${SCREEN_MEDIA.small} {
        width: 78%;
        right: 5%;
        font-size:26px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        width: 70%;
        right: 6%;
        font-size:28px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        width: 65%;
        right: 8%;
        font-size:32px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        width: 60%;
        right: 10%;
        font-size: 35px;
    }
`;

const Slide = forwardRef((props, ref) => {

    return (
        <SlideStyled
            ref={ref}
            id='slide'
            background={props.bg_path}
            layer_opacity={props.layer_opacity}>
            <Title
                text_color={COLOR.white}
                font_family={FONT_FAMILY.noteworthyBold}
                position='absolute'>
                {props.title}
            </Title>
            <Description
                text_color={COLOR.white}
                font_family={FONT_FAMILY.noteworthyBold}
                position='absolute'>
                {props.description}
            </Description>
        </SlideStyled>
    );
});

Slide.propTypes = {
    bg_path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    layer_opacity: PropTypes.number
}

export default Slide;