import { CATEGORY, THEMATIC } from './constants.js';

import PathImgBaptism_001 from '../images_categories/baptism/img_001.jpg';
import PathImgBaptism_002 from '../images_categories/baptism/img_002.jpg';
import PathImgBaptism_003 from '../images_categories/baptism/img_003.jpg';
import PathImgBaptism_004_001 from '../images_categories/baptism/img_004_001.jpg';
import PathImgBaptism_004_002 from '../images_categories/baptism/img_004_002.jpg';
import PathImgBaptism_005_001 from '../images_categories/baptism/img_005_001.jpg';
import PathImgBaptism_005_002 from '../images_categories/baptism/img_005_002.jpg';


const PICTURES_BAPTISM = [
    {
        src: PathImgBaptism_005_001,
        category: CATEGORY.baptism,
        thematic: THEMATIC.none,
        title: CATEGORY.baptism.title
    },
    {
        src: PathImgBaptism_005_002,
        category: CATEGORY.baptism,
        thematic: THEMATIC.none,
        title: CATEGORY.baptism.title
    },
    {
        src: PathImgBaptism_004_001,
        category: CATEGORY.baptism,
        thematic: THEMATIC.none,
        title: CATEGORY.baptism.title
    },
    {
        src: PathImgBaptism_004_002,
        category: CATEGORY.baptism,
        thematic: THEMATIC.none,
        title: CATEGORY.baptism.title
    },
    {
        src: PathImgBaptism_001,
        category: CATEGORY.baptism,
        thematic: THEMATIC.none,
        title: CATEGORY.baptism.title
    },
    {
        src: PathImgBaptism_003,
        category: CATEGORY.baptism,
        thematic: THEMATIC.none,
        title: CATEGORY.baptism.title
    },
    {
        src: PathImgBaptism_002,
        category: CATEGORY.baptism,
        thematic: THEMATIC.none,
        title: CATEGORY.baptism.title
    }
]

export { PICTURES_BAPTISM }