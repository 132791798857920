const XMAS_WINNERS_MODAL = {
    id: 'modalXmasWinners',
    title: 'Muchas felicidades a los ganadores del sorteo!',
    winners: [
        {
            name: 'Alexia Rodríguez'
        },
        {
            name: 'Cristina Miñano Castillo'
        },
        {
            name: 'Deysi Lavado Mariños'
        }
    ]
}

export {
    XMAS_WINNERS_MODAL
}