import { CATEGORY, THEMATIC } from './constants.js';

import PathImgRomantic_001_001 from '../images_categories/romantic/img_001_001.jpg'
import PathImgRomantic_001_002 from '../images_categories/romantic/img_001_002.jpg'
import PathImgRomantic_001_005 from '../images_categories/romantic/img_001_005.jpg'
import PathImgRomantic_002_001 from '../images_categories/romantic/img_002_001.jpg'
import PathImgRomantic_002_002 from '../images_categories/romantic/img_002_002.jpg'
import PathImgRomantic_002_003 from '../images_categories/romantic/img_002_003.jpg'
import PathImgRomantic_002_004 from '../images_categories/romantic/img_002_004.jpg'
import PathImgRomantic_002_005 from '../images_categories/romantic/img_002_005.jpg'


const PICTURES_ROMANTIC = [
    {
        src: PathImgRomantic_002_001,
        category: CATEGORY.romantic,
        thematic: THEMATIC.romanticMarriageProposal,
        title: THEMATIC.romanticMarriageProposal.title
    },
    {
        src: PathImgRomantic_002_002,
        category: CATEGORY.romantic,
        thematic: THEMATIC.romanticMarriageProposal,
        title: THEMATIC.romanticMarriageProposal.title
    },
    {
        src: PathImgRomantic_002_003,
        category: CATEGORY.romantic,
        thematic: THEMATIC.romanticMarriageProposal,
        title: THEMATIC.romanticMarriageProposal.title
    },
    {
        src: PathImgRomantic_002_004,
        category: CATEGORY.romantic,
        thematic: THEMATIC.romanticMarriageProposal,
        title: THEMATIC.romanticMarriageProposal.title
    },
    {
        src: PathImgRomantic_002_005,
        category: CATEGORY.romantic,
        thematic: THEMATIC.romanticMarriageProposal,
        title: THEMATIC.romanticMarriageProposal.title
    },
    {
        src: PathImgRomantic_001_001,
        category: CATEGORY.romantic,
        thematic: THEMATIC.romanticAnniversary,
        title: THEMATIC.romanticAnniversary.title
    },
    {
        src: PathImgRomantic_001_002,
        category: CATEGORY.romantic,
        thematic: THEMATIC.romanticAnniversary,
        title: THEMATIC.romanticAnniversary.title
    },
    {
        src: PathImgRomantic_001_005,
        category: CATEGORY.romantic,
        thematic: THEMATIC.romanticAnniversary,
        title: THEMATIC.romanticAnniversary.title
    },
]

export { PICTURES_ROMANTIC }